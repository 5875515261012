<script setup lang="ts">
import {isBoolean, isNil, isNull} from "lodash";

import {formatSiren} from "@/utils/formatRegistrationNumber";
import {Establishment} from "@/utils/companyEstablishments";
import {formatLocaleDate, translate} from "@/i18n";

import ContentCard from "@/components/card/ContentCard.vue";
import CopyToClipboard from "@/components/copy-to-clipboard/CopyToClipboard.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";

const props = withDefaults(
    defineProps<{
      establishments: Establishment[];
      canEstablishments: boolean;
      companyCountry: string;
    }>(),
    {
      canEstablishments: false,
      companyCountry: "FR",
    }
);

const displayEstablishmentStatus = (isActive: boolean | null) => {
  if (isNil(isActive)) return '-';
  return isActive ? translate("COMPANY.IN_OPERATION") : translate("COMPANY.CLOSED");
};
</script>

<template>
  <ContentCard
      v-if="!canEstablishments"
      :title="translate('COMPANY.ESTABLISHMENTS')"
      class="establishments"
      :checked-display-content="false"
      :is-padlock="true"
  />
  <template v-else>
    <ContentCard
        :title="translate('COMPANY.ESTABLISHMENTS')"
        class="establishments"
    >
      <div
          v-for="(establishment, index) in establishments"
          :key="index"
          class="establishments-content breakinside"
      >
        <div class="establishments-content-first-block">
          <img
              v-if="establishment.headquarter"
              :src="require('../../../public/images/establishment-blue.png')"
              alt="establishment-blue"
              class="establishments-content-image"
          />
          <img
              v-else
              :src="require('../../../public/images/home.png')"
              alt="home"
              class="establishments-content-image"
          />
          <div class="establishments-content-operation">
            <span>{{
                `${
                    establishment.headquarter
                        ? translate("COMPANY.OFFICE")
                        : translate("COMPANY.BRANCH")
                }`
              }}</span>
            <span>{{ `${displayEstablishmentStatus(establishment.isActive)}` }}</span>
          </div>
          <div v-if="establishment.siret">
            <span>{{ formatSiren(companyCountry, establishment.siret) }}</span>
            <CopyToClipboard :value="establishment.siret"/>
          </div>
        </div>
        <div>
          <LabelValuePrinter
              :label="translate('ADDRESS.TITLE')"
              isAddress
              :value="`${
              establishment?.address?.address
                ? establishment.address.address
                : ''
            } ${
              establishment?.address?.zipcode
                ? establishment.address.zipcode
                : ''
            } ${
              establishment?.address?.city ? establishment.address.city : ''
            } ${
              establishment?.address?.country
                ? establishment.address.country
                : ''
            }`"
          />
          <LabelValuePrinter
              :label="translate('COMPANY.CREATED_DATE')"
              :value="`${
              establishment.creationDate
                ? formatLocaleDate(establishment.creationDate)
                : '-'
            }`"
          />
          <LabelValuePrinter
              v-if="establishment.closureDate"
              :label="translate('COMPANY.CLOSURE_DATE')"
              :value="`${
              establishment.closureDate
                ? formatLocaleDate(establishment.closureDate)
                : '-'
            }`"
          />
        </div>
      </div>
    </ContentCard>
  </template>
</template>
