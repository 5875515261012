<script setup lang="ts">
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";
import {computed, onMounted, ref} from "vue";
import Select from "@/components/select/Select.vue";
import {isArray, isEmpty} from "lodash";

const props = withDefaults(
    defineProps<{
      caseNumber: string;
      caseState: string;
      journeyId: string;
      states: Array<string>
    }>(), {
      caseNumber: '',
      caseState: '',
      journeyId: ''
    }
);

const emit = defineEmits(["delete-folder", "edit-folder", "save-folder"]);


const displaySaveButton = ref(false);
const state = ref('');

const valueDisplay = computed(() => {
  return [{label: 'TABLE.FILE_NUMBER', value: props.caseNumber}, {
    label: 'STATE',
    value: translate(`FOLDER.STATE.${props.caseState}`)
  }]
});

const disabledSaveButton = computed(() => {
  return state.value === props.caseState;
});

const statesValues = computed(() => {
  return !isEmpty(props.states) && isArray(props.states) ? props.states?.map((elt: any) => elt) : [];
});

const statesOptions = computed(() => {
  return !isEmpty(props.states) && isArray(props.states) ? props.states?.map((elt: any) => translate(`FOLDER.STATE.${elt}`)) : [];
});

const deleteFolder = (item: Object) => {
  emit('delete-folder', item);
};

const editFolder = () => {
  displaySaveButton.value = !displaySaveButton.value;
  emit('edit-folder');
};

const saveFolder = () => {
  emit('save-folder', state.value, props.caseState);
  displaySaveButton.value = !displaySaveButton.value;
};

onMounted(() => {
  state.value = props.caseState;
});
</script>

<template>
  <div class="box-input-state-folder">
    <div class="box-input-state-folder__wrapper">
      <div v-for="values in valueDisplay" class="box-input-state-folder__wrapper__paragraph">
        <span class="box-input-state-folder__underline">{{ `${translate(values.label)} :` }}</span>
        <span v-if="!displaySaveButton || values.label === 'TABLE.FILE_NUMBER'"
              class="box-input-state-folder__label">{{ ` ${values.value}` }}</span>
        <Select v-else-if="displaySaveButton && values.label === 'STATE'" v-model:value="state"
                :values="statesValues"
                :options="statesOptions" class="box-input-state-folder__select"/>
      </div>
    </div>
    <div v-if="!displaySaveButton" class="box-input-state-folder__content">
      <Button :label="translate('BUTTONS.MODIFY')" @click="editFolder"/>
      <Button :label="translate('BUTTONS.DELETE')" class="box-input-state-folder__content__delete"
              @click="deleteFolder"/>
    </div>
    <div v-else class="box-input-state-folder__content">
      <Button :label="translate('BUTTONS.SAVE')" @click="saveFolder" class="box-input-state-folder__content__save"
              :disabled="disabledSaveButton"/>
    </div>
  </div>
</template>