import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const applyRule = (rule: string, journeyId: string) =>
  api.put(`${envConfig.RULES_ENGINE_ROUTE}/api/v2/rule/apply?journeyId=${journeyId}&rule=${rule}`, {})
    .then((response) => response.data)
    .catch((error) => {
      return false
    });


export default {applyRule};