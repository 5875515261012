import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e29bdcd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-input-customer-info__form" }
const _hoisted_2 = { class: "box-input-customer-info__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createBlock(_component_ContentCard, {
    title: _ctx.translate('CLIENT_INFORMATION'),
    class: "box-input-customer-info"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TextInput, {
          class: "form-b2c-bloc-input-result",
          label: _ctx.translate('IDENTITY.FIRSTNAME'),
          modelValue: _ctx.clientForm.firstNames.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.clientForm.firstNames.value) = $event)),
          type: _ctx.NAME,
          disabled: _ctx.clientForm.firstNames.disabled,
          required: ""
        }, null, 8, ["label", "modelValue", "type", "disabled"]),
        _createVNode(_component_TextInput, {
          class: "form-b2c-bloc-input-result",
          label: _ctx.translate('IDENTITY.NAME'),
          modelValue: _ctx.clientForm.lastName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clientForm.lastName.value) = $event)),
          type: _ctx.NAME,
          disabled: _ctx.clientForm.lastName.disabled,
          required: ""
        }, null, 8, ["label", "modelValue", "type", "disabled"]),
        _createVNode(_component_TextInput, {
          class: "form-b2c-bloc-input-result mail",
          label: _ctx.translate('RECOVERY.CLIENT_ID'),
          modelValue: _ctx.clientForm.idCustomer.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.clientForm.idCustomer.value) = $event)),
          disabled: _ctx.clientForm.idCustomer.disabled,
          required: ""
        }, null, 8, ["label", "modelValue", "disabled"]),
        _createVNode(_component_TextInput, {
          class: "form-b2c-bloc-input-result mail",
          label: _ctx.translate('IDENTITY.EMAIL'),
          modelValue: _ctx.clientForm.email.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.clientForm.email.value) = $event)),
          type: _ctx.EMAIL,
          disabled: _ctx.clientForm.email.disabled,
          required: ""
        }, null, 8, ["label", "modelValue", "type", "disabled"]),
        _createVNode(_component_PhoneInput, {
          phoneValue: _ctx.clientForm.phone.value,
          "onUpdate:phoneValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clientForm.phone.value) = $event)),
          phoneCountry: _ctx.phoneCountry,
          "onUpdate:phoneCountry": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.phoneCountry) = $event)),
          "disable-phone-value": _ctx.clientForm.phone.disabled,
          required: true,
          class: "phone"
        }, null, 8, ["phoneValue", "phoneCountry", "disable-phone-value"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          "type-button": "submit",
          label: _ctx.translate('BUTTONS.VALIDATE'),
          onClick: _ctx.validateInformations,
          disabled: _ctx.validateForm()
        }, null, 8, ["label", "onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}