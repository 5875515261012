<script setup lang="ts">
import { isEqual } from "lodash";

import { translate } from "@/i18n";
import { KeyValueType } from "@/utils/sortArrayObjectKeys";

import AuthenticityResult from "@/components/document-analysis/AuthenticityResults.vue";
import BankDetailsResults from "@/components/document-analysis/BankDetailsResults.vue";
import { default as IdCheckResults } from "@/components/document-analysis/IdCheckResults.vue";
import IncomeTaxResults from "@/components/document-analysis/IncomeTaxResults.vue";
import KbisIndividualResults from "@/components/document-analysis/KbisIndividualResults.vue";
import KbisResults from "@/components/document-analysis/KbisResults.vue";
import PayslipResults from "@/components/document-analysis/PayslipResults.vue";
import PropertyTaxResults from "@/components/document-analysis/PropertyTaxResults.vue";
import TaxReportResults from "@/components/document-analysis/TaxReportResults.vue";
import PrintCard from "@/components/print/shared/PrintCard.vue";

const props = defineProps<{
  title: string;
  documentChecks: any[];
}>();

const isIdCheck = (documentType: string): boolean => {
  return (
    documentType === "DRIVING_LICENSE" ||
    documentType === "ID_CARD" ||
    documentType === "PASSPORT" ||
    documentType === "RESIDENCE_PERMIT"
  );
};

const isTaxReport = (docType: string): boolean => {
  return docType === "TAX_REPORT_S" || docType === "TAX_REPORT_C";
};

const getComponent = (docType: string) => {
  switch (true) {
    case docType === "AUTHENTICITY":
      return AuthenticityResult;
    case docType === "COMPANY_KBIS":
      return KbisResults;
    case docType === "INDIVIDUAL_KBIS":
      return KbisIndividualResults;
    case docType === "PROPERTY_TAX":
      return PropertyTaxResults;
    case docType === "BANK_DETAILS":
      return BankDetailsResults;
    case docType === "PAYSLIP":
      return PayslipResults;
    case docType === "INCOME_TAX":
      return IncomeTaxResults;
    case isIdCheck(docType):
      return IdCheckResults;
    case isTaxReport(docType):
      return TaxReportResults;
    default:
      return "";
  }
};

const getAnalysisChecks = (doc: any) => {
  if (doc.contextData.tax_notice_analysis.length > 0) {
    const taxNoticeAnalysis = doc.contextData.tax_notice_analysis.filter(
      (item: any) => {
        return item.documentIds[0] === doc.documentId[0];
      }
    );
    return taxNoticeAnalysis[0]?.checks;
  }
  return {};
};

const getProviderFromIdCheckData = (
  idCheckData: {
    [key: string]: { [key: string]: { [key: string]: KeyValueType } };
  }[],
  documentIds: string[]
) => {
  const checkData = idCheckData.filter((el) =>
    isEqual(el.documentIds, documentIds)
  );
  return checkData.length > 0 ? checkData[0].provider : "";
};
</script>

<template>
  <PrintCard
    :title="title || 'PRINT.DOCUMENT_ANALYSIS.TITLE'"
    class="print-card-document-analysis"
  >
    <div
      class="print-document-analysis-result-file-container breakinside"
      v-for="doc in props.documentChecks"
    >
      <div class="print-document-analysis-result-file-detail">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.TYPE") }} :
        </span>
        <span class="table-document-detail-result-analysis-titles-value">
          {{
            translate(
              `PRINT.DOCUMENT_ANALYSIS.DOCUMENT_CONTROL.${
                doc.documentType === "OCR"
                  ? doc.documentChecks.DOC_TYPE
                  : doc.documentType
              }`
            )
          }}
        </span>
      </div>
      <div class="print-document-analysis-result-file-detail">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.NAME") }} :
        </span>
        <span class="table-document-detail-result-analysis-titles-value">
          &nbsp;{{ doc.documentName[0] }}
        </span>
      </div>
      <div
        v-if="doc.documentType && doc.documentType === 'AUTHENTICITY'"
        class="print-document-analysis-result-file-detail"
      >
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.COMPLIANCE") }} :
        </span>
        <img
          class="print-document-analysis-result-file-detail-image"
          :src="`/images/${doc.documentChecks?.AUTHENTICITY}`"
          :alt="doc.documentChecks?.AUTHENTICITY"
        />
      </div>
      <div class="print-document-analysis-result-file-detail-label">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.DETAILS") }}
        </span>
      </div>
      <component
        :is="getComponent(doc.documentChecks.DOC_TYPE || doc.documentType)"
        :type="doc.documentChecks.DOC_TYPE"
        :checks="doc.documentChecks"
        :contextData="doc.contextData"
        :documentId="doc && doc.documentId ? doc.documentId[0] : ''"
        :analysis-checks="getAnalysisChecks(doc)"
        :is-to-print="true"
        :provider="
          getProviderFromIdCheckData(
            doc.contextData.id_check_data,
            doc.documentId
          )
        "
      />
    </div>
  </PrintCard>
</template>
