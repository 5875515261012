import {NavigationItem} from '@/components/sidebar/groups/NavigationItem';

const portalNavigation: NavigationItem[] = [
  {
    title: '',
    colorBackground: 'white',
    colorTitle: 'black',
    elements: [{
      title: 'SIDEBAR.KIT_B2C',
      route: '/analysis-b2c',
      requiredKit: 'KIT_B2C'
    }, {
      title: 'SIDEBAR.KIT_B2B',
      route: '/analysis-b2b',
      requiredKit: 'KIT_B2B'
    }, {
      title: 'SIDEBAR.KIT_OB',
      route: '/open-banking',
      requiredKit: 'KIT_OB'
    }, {
      title: 'SIDEBAR.KIT_DOC',
      route: '/document-analysis',
      requiredKit: 'KIT_DOC'
    }, {
      title: 'SIDEBAR.KIT_RECOVERY',
      route: '/recovery',
      requiredKit: 'KIT_RECOVERY'
    }]
  },
  {
    title: '',
    colorBackground: '#ffa543',
    colorTitle: 'black',
    elements: [{
      title: 'SIDEBAR.HISTORICAL',
      route: '/historical'
    }]
  },
  {
    title: '',
    colorBackground: '#D9D9D9',
    colorTitle: 'black',
    elements: [{
        title: 'SIDEBAR.BATCH',
        route: '/batch'
      }]
  }
];

export default portalNavigation;
