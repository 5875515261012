<template>
  <div>
    <div class="verification-result success" v-if="value === '🟢' || value === 'SUCCESS' || value" :title="tooltip">
      <span class="verification-result-label">{{ type }} {{ label }} </span>
    </div>
    <div v-if="!isWarning">
      <div class="verification-result error" v-if="value === '🔴' || !value" :title="tooltip">
        <span class="verification-result-label">
          <span v-if="!message"> {{ type }} {{ translate("NO") }} {{ label }} </span>
          <span v-else> {{ message }} </span>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="verification-result warning" v-if="value === '🔴' || !value" :title="tooltip">
        <span class="verification-result-label">
          <span v-if="!message"> {{ type }} {{ translate("NO") }} {{ label }} </span>
          <span v-else> {{ message }} </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {BIconXSquareFill, BIconCheckSquareFill} from "bootstrap-icons-vue";
import {translate} from '@/i18n';

export default defineComponent({
  name: "VerificationResult",
  methods: {translate},
  components: {BIconXSquareFill, BIconCheckSquareFill},
  props: {
    value: {type: String, default: ''},
    label: {type: String, default: ''},
    type: {type: String, default: ''},
    tooltip: {type: String, default: ''},
    isWarning: {type: Boolean, default: false},
    message: {type: String, default: ''}
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.verification-result {
  border-radius: 10px;
  width: 90%;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  margin: auto;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  color: $greenMeelo;
  border: solid $greenMeelo 3px;
}

.error {
  color: $redMeelo;
  border: solid $redMeelo 3px;
}

.warning {
  color: $orangeMeelo;
  border: solid $orangeMeelo 3px;
}

.results-page-body-leader-bloc-details-telephone-results, .results-page-b2c-body-leader-bloc-details-telephone-results, .telephone-details {
  div {
    margin: auto;
  }
}

.causes-exists-verification {
  .verification-result {
    height: 2.5rem;
  }
}

.recovery-request-information-second-block-verification, .synthesis-recovery-summary-request-information-second-block-verification {
  .verification-result {
    height: 2rem;
    font-size: $fontSizeThirteenSize;
  }
}
</style>
