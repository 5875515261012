<script setup lang="ts">
import {ref} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";
import {TextInputType} from "@/types/TextInputType";
import InlineTextInput from "../input/InlineTextInput.vue";
import CalendarInput from "../input/CalendarInput.vue";
import {useStore} from "vuex";
import envConfig from "@/utils/envConfig";
import { watch } from "vue";
import { formatSiren } from "@/utils/formatRegistrationNumber";

const dossierType = [
  {label: translate('DECLARATION.FRAUD.INDIVIDUAL'), value: "particulier"},
  {label: translate('DECLARATION.FRAUD.PROFESIONAL'), value: "professionnel"}];

const avoidance = [
  {label: translate('YES'), value: "oui"},
  {label: translate('NO'), value: "non"}]

const emit = defineEmits(["send-fraud-declaration"]);

const registrationNumber = ref('')
const mail = ref('')
const phone = ref('')
const amount = ref('')
const url = ref('')
const tools = ref('')
const comment = ref('')

const agree = ref(false)
const selectedType = ref('particulier');
const avoid = ref('oui')
const currency = ref('EUR');
const highlightInput = ref(false)
const disabledButton = ref(true)
const date = ref(new Date().toISOString().split('T')[0]);

const store = useStore()

const isValidateForm = (): boolean => {
  if ((date.value === '' || mail.value === '' || selectedType.value === '' || phone.value === '' || agree.value === false) || (selectedType.value === 'professionnel' && registrationNumber.value === '')) {
    disabledButton.value = true
    return true
  } else {
    disabledButton.value = false
    return false
  }
}

const sendInfos = (e: SubmitEvent) => {
  e.preventDefault();
  const agreeValue = agree.value ? "oui" : "non";
  if (isValidateForm()) {
    highlightInput.value = true;
    setTimeout(() => {
      highlightInput.value = false;
    }, 3000);
  }
  const declaration = {
    "to": [envConfig.MAIL_FRAUD_REPORT],
    "templateContent": {
      "##companyName##": store.getters.user.company.name,
      "##userName##": store.getters.user.username,
      "##userMail##": store.getters.user.email,
      "##date##": date.value,
      "##type##": selectedType.value,
      "##siren##": registrationNumber.value.split(' ').join(''),
      "##mail##": mail.value,
      "##phone##": phone.value,
      "##amount##": amount.value,
      "##currency##": currency.value,
      "##url##": url.value,
      "##avoid##": avoid.value,
      "##tools##": tools.value,
      "##details##": comment.value,
      "##agree##": agreeValue
    }
  }
  emit("send-fraud-declaration", declaration);
  disabledButton.value = true;
}

watch(() => registrationNumber.value, ()=> {
  registrationNumber.value = formatSiren("FR", registrationNumber.value)
})
</script>
<template>
  <ContentCard :title="translate('DECLARATION.FRAUD.TITLE')" class="fraud-declation">
    <div class="fraud-declation-first-block">
      <span>{{ translate('DECLARATION.FRAUD.EXPLANATION') }}</span>
    </div>
    <hr class="fraud-declation-first-block-line"/>
    <div class="fraud-declation-second-block">
      <form ref="form" name="formFraud" method="post">
        <div class="fraud-declation-second-block-input-date">
          <CalendarInput :label="`${translate('DECLARATION.FRAUD.DATE')}:`" :type="TextInputType.DATE" v-model="date"
                         :highlight="highlightInput"/>
        </div>
        <div class="fraud-declation-second-block-radio">
          <span class="underline">{{ `${translate('DECLARATION.FRAUD.TYPE')}:` }}</span>
          <ul>
            <li v-for="(dossier,index) in dossierType" :key="index">
              <label :for="'dossier_' + index">
                <input type="radio" v-model="selectedType" :value="dossier.value" :id="'dossier_'+index">
                <span>{{ dossier.label }}</span>
              </label>
            </li>
          </ul>
        </div>
        <div>
          <div v-show="selectedType === 'professionnel'" class="fraud-declation-second-block-input">
            <InlineTextInput :label="`*${translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.SIREN')}:`"
                              v-model="registrationNumber"
                             :highlight="highlightInput"/>
          </div>
          <div class="fraud-declation-second-block-input">
            <InlineTextInput :label="`${translate('DECLARATION.FRAUD.MAIL')}:`" v-model="mail"
                             :type="TextInputType.EMAIL "
                             :highlight="highlightInput"/>
            <InlineTextInput :label="`${translate('DECLARATION.FRAUD.PHONE')}:`" :type="TextInputType.PHONE_NUMBER"
                             v-model="phone" :highlight="highlightInput"/>
          </div>
          <div class="fraud-declation-second-block-amount">
            <InlineTextInput for="amount-currency" :type="TextInputType.NUMBER"
                             :label="`${translate('DECLARATION.FRAUD.AMOUNT')}:`" v-model="amount">
              <select name="currency" id="amount-currency" v-model="currency">
                <option value="EUR" selected>EUROS (€)</option>
                <option value="DOL">DOLLARS ($)</option>
              </select>
            </InlineTextInput>
          </div>
          <div class="fraud-declation-second-block-input">
            <InlineTextInput :label="`${translate('DECLARATION.FRAUD.URL')}:`" :placeholder="translate('RECOMMENDED')"
                             v-model="url"/>
          </div>
          <div class="fraud-declation-second-block-radio">
            <span class="underline">{{ `${translate('DECLARATION.FRAUD.AVOID')}` }}</span>
            <ul>
              <li v-for="(response,index) in avoidance" :key="index">
                <label :for="'response_' + index">
                  <input type="radio" v-model="avoid" :value="response.value" :id="'response_'+index">
                  <span>{{ response.label }}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="fraud-declation-second-block-input">
            <InlineTextInput :label="`${translate('DECLARATION.FRAUD.TOOLS')}:`" v-model="tools"/>
          </div>
          <div class="fraud-declation-second-block-commentaire">
            <label class="fraud-declation-second-block-commentaire-label"
                   for="comment">{{ `${translate('DECLARATION.FRAUD.COMMENT')}` }}</label>
            <textarea class="fraud-declation-second-block-commentaire-text-area input" name="comment" id="comment" cols="146"
                      rows="5" v-model="comment"></textarea>
          </div>
          <div class="fraud-declation-second-block-condition">
            <input type="checkbox" id="acceptation" name="acceptation" v-model="agree">
            <label for="acceptation">{{ translate('DECLARATION.FRAUD.CONDITION_FIRST') }}
              <a href="/files/general_conditions_fraud_declaration.pdf" target="_blank">{{
                translate('DECLARATION.FRAUD.CONDITION_SECOND_LINK')
              }}</a>{{ translate('DECLARATION.FRAUD.CONDITION_THIRD') }}</label>
          </div>
          <div class="fraud-declation-second-block-button">
            <Button :label="translate('BUTTONS.SEND') " :disabled="isValidateForm() || disabledButton"
                    @click="sendInfos"/>
          </div>
        </div>
      </form>
    </div>
  </ContentCard>
</template>