import {phoneIndicator} from "@/utils/phoneIndicator";
import {formatPhoneNumber} from "@/utils/formatPhoneNumber";

export function usePhone() {
  const phoneCountryCodes = {
    ES: "+34",
    BE: "+32"
  };

  const checkPhoneValue = (phone: string, phoneCountry: string) => {
    const phoneFormated = formatPhoneNumber(phone, phoneCountry);
    if (!phoneFormated) return false;
    const indicator = phoneFormated?.substring(0, 3);
    if (phoneCountry === "+") {
      return phoneFormated.length > 1; //no condition if the indicator is '+' (just need one number)
    }
    if (phoneIndicator.find((item) => item.value === indicator)) {
      const length = phoneIndicator?.filter(
        (obj) => obj.value === indicator
      )[0]["length"];
      return phoneFormated && phoneFormated !== "" && length.includes(phoneFormated.length);
    }
  };

  const computePhoneCountry = (country: string) => {
    // @ts-ignore
    const countryCode = phoneCountryCodes[country] as string | undefined;
    if (countryCode) {
      return countryCode;
    }
    return "+33";
  };

  return {checkPhoneValue, computePhoneCountry};
}
