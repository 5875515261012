<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import Loader from "@/components/loader/Loader.vue";
import CheckIbanBank from "@/components/check-iban/CheckIbanBank.vue";

const props = withDefaults(defineProps<{
  checkIban: Object,
  loader: boolean,
  birthdate: string,
  messageError: boolean,
  canCheckIban: boolean
}>(), {
  loader: false,
  birthdate: '',
  messageError: false,
  canCheckIban: false,
});

const calculateNote = (check: string) => {
  return (parseInt(check) / 400) * 100;
}
</script>
<template>
  <ContentCard v-if="!canCheckIban" :checked-display-content="false" :is-padlock="true" class="applicant-b2c"
               :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`"/>
  <template v-else>
    <ContentCard :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`" class="check-iban-result">
      <CheckIbanBank :bank-name="checkIban?.bank?.name" :iban="checkIban?.bank?.iban" :bic="checkIban?.bank?.bic"/>
      <Loader v-if="loader"/>
      <div v-if="!loader">
        <template v-if="!messageError">
          <span class="check-iban-result-subtitle">{{ translate('CHECK_IBAN.RESULT.VERIFICATION') }} :</span>
          <div class="check-iban-result-details">
            <div v-for="(detail, index) in checkIban.detailedChecks" :key="index">
              <template v-if="detail.Check.scope === 'iban'">
            <span v-if="detail.Check.result === '001'">
              <img :src="require('/public/images/success-icon.png')" alt="success-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.IBAN_EXISTS') }}
            </span>
                <span v-if="detail.Check.result === '010'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.IBAN_NOT_EXISTS_ANYMORE') }}
            </span>
                <span v-if="detail.Check.result === '030'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.IBAN_NOT_EXISTS') }}
            </span>
                <span v-if="detail.Check.result === '040'">
              <img :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.IBAN_CAN_BE_VERIFIED') }}
            </span>
              </template>
              <template v-if="detail.Check.scope === 'typeTitulaire'">
            <span v-if="detail.Check.result === '001'">
              <img :src="require('/public/images/success-icon.png')" alt="success-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.HOLDER_TYPE_CORRECT') }}
            </span>
                <span v-if="detail.Check.result === '000'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.HOLDER_TYPE_INCORRECT') }}
            </span>
              </template>
              <template v-if="detail.Check.scope === 'siren'">
            <span v-if="detail.Check.result === '001'">
              <img :src="require('/public/images/success-icon.png')" alt="success-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.SIREN_MATCH') }}
            </span>
                <span v-if="detail.Check.result === '000'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.SIREN_NOT_MATCH') }}
            </span>
                <span v-if="detail.Check.result === '020'">
              <img :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.SIREN_CANNOT_CONTROL') }}
            </span>
              </template>
              <template v-if="detail.Check.scope === 'dateNaissance'">
            <span v-if="detail.Check.result === '001'">
              <img :src="require('/public/images/success-icon.png')" alt="success-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.BIRTHDATE_MATCH') }}
            </span>
                <span v-if="detail.Check.result === '000'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.BIRTHDATE_NOT_MATCH') }}
            </span>
                <span v-if="detail.Check.result === '020'">
              <img :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.BIRTHDATE_CANNOT_CONTROL') }}
            </span>
              </template>
              <template v-if="detail.Check.scope === 'nom' && checkIban.reasonCode?.code !== 'RC11'">
            <span v-if="calculateNote(detail.Check.result) >= 70">
              <img :src="require('/public/images/success-icon.png')" alt="success-icon.png"
                   class="check-iban-result__icon">
              {{
                `${translate(`CHECK_IBAN.RESULT.${detail.Check.scope}`)} (match ${calculateNote(detail.Check.result).toFixed(2)}%)`
              }}
            </span>
                <span v-if="calculateNote(detail.Check.result) < 40">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{
                    `${translate(`CHECK_IBAN.RESULT.${detail.Check.scope}`)} (match ${calculateNote(detail.Check.result).toFixed(2)}%)`
                  }}
            </span>
                <span v-if="calculateNote(detail.Check.result) >= 40 && calculateNote(detail.Check.result) < 70">
              <img :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                   class="check-iban-result__icon">
              {{
                    `${translate(`CHECK_IBAN.RESULT.${detail.Check.scope}`)} (match ${calculateNote(detail.Check.result).toFixed(2)}%)`
                  }}
            </span>
              </template>
              <template
                  v-if="detail.Check.scope === 'message' && birthdate !== '' && checkIban.reasonCode?.code !== 'RC11'">
            <span v-if="detail.Check.result === '000'">
              <img :src="require('/public/images/failed-icon.png')" alt="failed-icon.png"
                   class="check-iban-result__icon">
              {{ translate('CHECK_IBAN.RESULT.BIRTHDATE_NOT_MATCH') }}
            </span>
              </template>
            </div>
          </div>
          <div class="check-iban-result-acknowledgment">
        <span v-if="checkIban.acknowledgment?.ack === 'NAK'"><img
            :src="require('/public/images/failed-icon.png')"
            alt="failed-icon.png"
            class="check-iban-result__icon">{{
            ` ${translate('CHECK_IBAN.RESULT.BANK_CONNECTION')}`
          }}</span>
            <span v-if="checkIban.acknowledgment?.ack === 'ACK'"><img
                :src="require('/public/images/success-icon.png')"
                alt="success-icon.png"
                class="check-iban-result__icon">{{
                ` ${translate('CHECK_IBAN.RESULT.BANK_CONNECTION')}`
              }}</span>
          </div>
          <div class="check-iban-result-reason-code">
            <div class="check-iban-result-reason-code">
          <span
              v-if="checkIban.reasonCode?.code === 'AC01'"><img :src="require('/public/images/warning-icon.png')"
                                                                alt="warning-icon.png"
                                                                class="check-iban-result__icon">{{
              `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.IncorrectAccountNumber')}`
            }}
          </span>
              <span
                  v-if="checkIban.reasonCode?.code === 'BE05'"><img
                  :src="require('/public/images/warning-icon.png')"
                  alt="warning-icon.png"
                  class="check-iban-result__icon">{{
                  `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.UnrecognisedInitiatingParty')}`
                }}
          </span>
              <span
                  v-if="checkIban.reasonCode?.code === 'BE15'"><img
                  :src="require('/public/images/warning-icon.png')"
                  alt="warning-icon.png"
                  class="check-iban-result__icon">{{
                  `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.InvalidIdentificationCode')}`
                }}
          </span>
              <span v-if="checkIban.reasonCode?.code === 'BE21'"><img
                  :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                  class="check-iban-result__icon">{{
                  `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.MissingName')}`
                }}
          </span>
              <span v-if="checkIban.reasonCode?.code === 'FF01'"><img
                  :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                  class="check-iban-result__icon">{{
                  `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.InvalidData')}`
                }}
          </span>
              <span v-if="checkIban.reasonCode?.code === 'RC11'"><img
                  :src="require('/public/images/warning-icon.png')" alt="warning-icon.png"
                  class="check-iban-result__icon">{{
                  `️ ${translate('CHECK_IBAN.RESULT.REASON_CODE.BICInvalid')}`
                }}
          </span>
            </div>
          </div>
        </template>
        <template v-else>
          <span>{{ translate('CHECK_IBAN.ERROR_MESSAGE') }}</span>
        </template>
      </div>
    </ContentCard>
  </template>
</template>