import api from '@/utils/api';
import envConfig from "@/utils/envConfig";

export const getRules = (journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/api/v2/rules`;
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.get(url).then(response => response.data);
}
   

export const getRule = (name: string, ruleV2: boolean, globalRule: boolean, journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/api/v2/rule/infos?name=${name}&ruleV2=${ruleV2}&globalRule=${globalRule}`;
    if (journeyId) url += `&journeyId=${journeyId}`;
    return api.get(url)
    .then(response => response.data);
}

export const changeParams = (params: any, journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/api/v2/params`;
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.put(url, params)
    .then(response => response.data);
}

export const getSets = (journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/api/v2/sets`
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.get(url)
    .then(response => response.data);
}

export default {getRules, getRule, changeParams};
