<template>
  <ContentCard :title="translate('CLIENT_INFORMATION')" class="box-input-customer-info">
    <div class="box-input-customer-info__form">
      <TextInput class="form-b2c-bloc-input-result"
                 :label="translate('IDENTITY.FIRSTNAME')"
                 v-model="clientForm.firstNames.value"
                 :type="NAME"
                 :disabled="clientForm.firstNames.disabled" required/>
      <TextInput class="form-b2c-bloc-input-result" :label="translate('IDENTITY.NAME')"
                 v-model="clientForm.lastName.value" :type="NAME" :disabled="clientForm.lastName.disabled"
                 required/>
      <TextInput class="form-b2c-bloc-input-result mail" :label="translate('RECOVERY.CLIENT_ID')"
                 v-model="clientForm.idCustomer.value"
                 :disabled="clientForm.idCustomer.disabled" required/>
      <TextInput class="form-b2c-bloc-input-result mail" :label="translate('IDENTITY.EMAIL')"
                 v-model="clientForm.email.value"
                 :type="EMAIL" :disabled="clientForm.email.disabled" required/>
      <PhoneInput v-model:phoneValue="clientForm.phone.value" v-model:phoneCountry="phoneCountry"
                  :disable-phone-value="clientForm.phone.disabled" :required="true" class="phone"/>
    </div>
    <div class="box-input-customer-info__action">
      <Button type-button="submit" :label="translate('BUTTONS.VALIDATE')" @click="validateInformations"
              :disabled="validateForm()"/>
    </div>
  </ContentCard>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import TextInput from "@/components/input/TextInput.vue";
import Button from "@/components/button/Button.vue";
import {getRegex, TextInputType} from "@/types/TextInputType";
import {formatPhoneNumber} from "@/utils/formatPhoneNumber";
import TemplateMixin from "@/mixins/templateMixin";
import {phoneIndicator} from "@/utils/phoneIndicator";
import PhoneInput from "@/components/input/PhoneInput.vue";

export default defineComponent({
  mixins: [TemplateMixin],
  methods: {
    translate,
    handleOptionSelected() {
      this.clientForm.phone.value = this.phoneCountry;
    },
    validateInformations() {
      this.clientForm.phone.value = formatPhoneNumber(this.clientForm.phone.value, this.phoneCountry);
      this.$emit('customer-information', this.customerProfileV3, this.clientForm.idCustomer.value);
    },
    validateForm() {
      return !(getRegex(TextInputType.EMAIL).test(this.clientForm.email.value)
          && getRegex(TextInputType.LETTERS).test(this.clientForm.firstNames.value)
          && getRegex(TextInputType.LETTERS).test(this.clientForm.lastName.value)
          && this.clientForm.phone.value.length > 11
          && this.clientForm.idCustomer.value !== '');
    }
  },
  components: {PhoneInput, Button, TextInput, ContentCard},
  data: () => ({
    EMAIL: TextInputType.EMAIL,
    LETTERS: TextInputType.LETTERS,
    NOT_NULL: TextInputType.NOT_NULL,
    PHONE_NUMBER: TextInputType.PHONE_NUMBER,
    NAME: TextInputType.NAME,
    countries: phoneIndicator,
    clientForm: {
      lastName: {disabled: false, value: ''},
      firstNames: {disabled: false, value: ''},
      phone: {disabled: false, value: '+33'},
      email: {disabled: false, value: ''},
      idCustomer: {disabled: false, value: ''}
    } as any,
    phoneCountry: '+33',
  }),
  watch: {
    propPrefillClientForm: {
      handler(data) {
        this.clientForm = data;

        this.clientForm.phone.value ? this.clientForm.phone.value.replaceAll(' ', '') : null;

        if (!this.clientForm.phone.value) this.clientForm.phone.value = this.phoneCountry;

      },
      immediate: true, // Pour déclencher le watcher dès le montage du composant
      deep: true,
    },
    cleanForm() {
      if (!this.cleanForm) return
      this.clientForm = this.propPrefillClientForm;
      this.phoneCountry = "+33";
      this.$emit("form-cleaned");
    }
  },
  props: {
    cleanForm: {type: Boolean, default: false},
    marketType: {type: String, default: ''},
    readOnly: {type: Boolean, default: false},
    customerProfile: {type: Object},
    propPrefillClientForm: {type: Object},
  },
  computed: {
    customerProfileV3() {
      return {
        identity: {
          lastName: this.clientForm.lastName.value,
          firstName: this.clientForm.firstNames.value,
          birthDate: null,
          birthPlace: null,
          email: this.clientForm.email.value,
          mobileNumber: this.clientForm.phone.value && this.clientForm.phone.value.length >= 5 ? this.clientForm.phone.value : null,
        },
        address: {
          street: null,
          city: null,
          zipCode: null,
          country: null
        }
      };
    }
  },
  mounted() {
    if (!this.readOnly) {
      return;
    }
    this.clientForm.lastName.value = this.customerProfile?.identity?.lastName;
    this.clientForm.firstNames.value = this.customerProfile?.identity?.firstName;
    this.clientForm.email.value = this.customerProfile?.identity?.email;
    this.clientForm.phone.value = this.customerProfile?.identity?.mobilePhoneNumber;
  },
})
</script>

<style scoped lang="scss">
.box-input-customer-info {
  margin: 1rem;

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &__action {
    text-align: center;
  }
}

.phone {
  padding-bottom: 1rem;
}

</style>
