<script setup lang="ts">
import { ref, watchEffect, onMounted } from 'vue';
import InlineTextInput from "@/components/input/InlineTextInput.vue";
import BackOfficeRulesTable from "@/components/backoffice/BackOfficeRulesTable.vue";
import Loader from "@/components/loader/Loader.vue";
import { translate } from '@/i18n';
import { getRules } from '@/apiCalls/backOffice/rulesEngine';

interface Rule {
  name: string;
  description: string;
  version: string;
  global: boolean;
  sets: any
}

const isLoading = ref(true);
const rules = ref<Rule[]>([]);
const filteredRules = ref<Rule[]>([]);
const isInSetFilter = ref(true);
const ruleNameFilter = ref<string|undefined>(undefined);
const ruleTypeGenericFilter = ref(true);
const ruleTypeSpecificFilter = ref(true);

const filterRules = () => {
  filteredRules.value = rules.value
    .filter((rule: any) => isInSetFilter.value ? rule.sets.length > 0 : true)
    .filter((rule: any) => ruleNameFilter.value ? rule.name.toLowerCase().includes(ruleNameFilter.value.toLowerCase()) : true)
    .filter((rule: any) => {
      if (ruleTypeGenericFilter.value && ruleTypeSpecificFilter.value) return true;
      else if (ruleTypeGenericFilter.value) return rule.global;
      else if (ruleTypeSpecificFilter.value) return !rule.global;
    });
};

watchEffect(() => {
  filterRules();
});

const refreshRules = async () => {
  isLoading.value = true;
  const response = await getRules();
  rules.value = response?.result?.rules;
  isLoading.value = false;
};

onMounted(async () => {
  await refreshRules();
});

</script>

<template>
  <div class="rules">
    <div class="rules__explanation">
      {{ translate('BACKOFFICE.RULES_ENGINE.RULES.EXPLANATION') }}
    </div>
    <div class="rules__section">
      <div class="rules__section__title">
        {{ translate('BACKOFFICE.RULES_ENGINE.RULES.RULES') }}
      </div>
      <div class="rules__section__content">
        <span class="rules__section__content__subtitle__text">{{ `${translate('RESEARCH')} :` }}</span>
        <div class="rules__section__content__header">
          <div class="rules__section__content__header__left">
            <div class="rules__section__content__header__subtitle">
              <InlineTextInput v-model="ruleNameFilter"
                               :label="`${translate('BACKOFFICE.RULES_ENGINE.RULES.NAME')} :`"/>
              <span class="rules__section__content__header__subtitle">
                <span
                  class="rules__section__content__header__subtitle__text">{{ translate('BACKOFFICE.RULES_ENGINE.RULES.CONTAINED_IN_A_SET') }}</span>
                <input type="checkbox" v-model="isInSetFilter">
              </span>
            </div>
          </div>
          <div class="rules__section__content__header__right">
            <div class="rules__section__content__rule-type-selection">
              <span class="rules__section__content__header__subtitle__text">
                {{ translate('BACKOFFICE.RULES_ENGINE.RULES.TYPE') }}
              </span>
              <div class="rules__section__content__rule-type-selection__checkboxes">
                <span>
                  <input type="checkbox" v-model="ruleTypeGenericFilter">
                  {{ translate('BACKOFFICE.RULES_ENGINE.RULES.GENERIC') }}
                </span>
                <span>
                  <input type="checkbox" v-model="ruleTypeSpecificFilter">
                  {{ translate('BACKOFFICE.RULES_ENGINE.RULES.SPECIFIC') }}
                </span>
              </div>

            </div>
          </div>
        </div>

        <BackOfficeRulesTable v-if="filteredRules.length!=0" :rules="filteredRules" :detailed-information="true"/>

        <Loader v-else-if="isLoading"/>

      </div>
    </div>
  </div>
</template>
