<script setup lang="ts">
import {ref, computed, onMounted} from "vue";
import Bar from "@/components/bar/Bar.vue";
import {isObject, size} from "lodash";
import {translate} from "@/i18n";

const props = defineProps({
  data: { type: Array, required: true },
  isMaintenance: { type: Boolean, required: true, default: false },
  wasMaintenance: { type: Boolean, required: true, default: false }
});

const type = computed(() => {
  if (props.isMaintenance) {
    return 'danger';
  } else if (props.wasMaintenance) {
    return 'success';
  }
});

const header = computed(() => {
  if (props.isMaintenance) {
    return translate('MAINTENANCE.ONGOING');
  } else if (props.wasMaintenance) {
    return translate('MAINTENANCE.FINISHED');
  }
});

const text = computed(() => {
  let text;
  if (props.isMaintenance) {
    text = translate("MAINTENANCE.TITLE");
    let services = props.data.map((service: any) => translate(`MAINTENANCE.SERVICE.${service}`));
    text += services.join(', ');
  } else if (props.wasMaintenance) {
    text = translate("MAINTENANCE.FINISHED_TITLE");
  } else {
    // we return an empty string if there is no maintenance
    return '';
  }
  return text;
});
</script>

<template>
  <Bar v-if="text" :type="type" :header="header" :text="text" />
</template>