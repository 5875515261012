<script setup lang="ts">
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import {MultiRequestsType} from "@/types/MultiRequestsType";
import {computed} from "vue";

const props = withDefaults(
    defineProps<{
      title: string,
      company: string,
      result: MultiRequestsType,
      marketType: string
    }>(),
    {
      title: '',
      company: '',
      marketType: ''
    }
);

const toDisplayResult = computed(() => {
  if (props.marketType === 'b2c') {
    return ['email', 'phone']
  } else if (props.marketType === 'b2b') {
    return ['email', 'registrationNumber', 'phone']
  } else if (props.marketType === 'open-banking') {
    return ['iban']
  }
})
</script>
<template>
  <ResultCard :title="title" class="multi-requests">
    <div class="multi-requests-content">
      <span>{{ translate('MULTI_REQUESTS.DESCRIPTION_1') }} {{ company }} {{
          translate('MULTI_REQUESTS.DESCRIPTION_2')
        }}</span>
      <span v-for="key in toDisplayResult" :key="key">
          <span v-if="result?.[key]">{{ translate(`MULTI_REQUESTS.${key.toUpperCase()}`) }} : {{ result[key] }}</span>
        </span>
    </div>
  </ResultCard>
</template>

