import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c79b2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "verification-result-label" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "verification-result-label" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "verification-result-label" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.value === '🟢' || _ctx.value === 'SUCCESS' || _ctx.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "verification-result success",
          title: _ctx.tooltip
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.type) + " " + _toDisplayString(_ctx.label), 1)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.isWarning)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.value === '🔴' || !_ctx.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "verification-result error",
                title: _ctx.tooltip
              }, [
                _createElementVNode("span", _hoisted_5, [
                  (!_ctx.message)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.type) + " " + _toDisplayString(_ctx.translate("NO")) + " " + _toDisplayString(_ctx.label), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.message), 1))
                ])
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.value === '🔴' || !_ctx.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "verification-result warning",
                title: _ctx.tooltip
              }, [
                _createElementVNode("span", _hoisted_10, [
                  (!_ctx.message)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.type) + " " + _toDisplayString(_ctx.translate("NO")) + " " + _toDisplayString(_ctx.label), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.message), 1))
                ])
              ], 8, _hoisted_9))
            : _createCommentVNode("", true)
        ]))
  ]))
}