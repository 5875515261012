<script setup lang="ts">
import { defineProps, ref } from 'vue';
import Button from "@/components/button/Button.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import { translate } from "@/i18n";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import TextInput from "@/components/input/TextInput.vue";
import BackOfficeRuleEditModal from "@/components/backoffice/BackOfficeRuleEditModal.vue";

const emit = defineEmits(['update-set-selected']);

const props = defineProps<{
  sets: Record<number, { setName: string }>;
}>();

const titles: any = ref(
  [
      "",
      translate('BACKOFFICE.RULES_ENGINE.SETS.NAME'),
  ]
);

const selectedSetIndex = ref<number | null>(null);

const formatSet = (set: { setName: string }) => {
  return {
    NAME: set.setName
  };
};

const selectSet = (index: number) => {
  emit("update-set-selected", props.sets[index]);
};

</script>

<template>
  <div class="backoffice-sets-table">
    <table>
      <thead>
      <tr>
        <th v-for="colsTitle in titles" scope="col" v-bind:key="colsTitle">
            {{ translate(colsTitle) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in sets" :key="index" @click="() => {selectedSetIndex = index; selectSet(index);}">
        <td>
          <input v-model="selectedSetIndex" :value="index" type="radio" @click="selectSet(index)">
        </td>
        <td v-for="(value, key) in Object.keys(formatSet(item))"
            :data-label="key"
            :key="key + index">
            {{ formatSet(item)[value] }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
