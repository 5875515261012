export const possibleServices = new Set([
  "AML_CFT",
  "CHECKOUT_PROXY",
  "DOCUMENT",
  "DOCUMENT_PROXY",
  "DOCUMENTATION",
  "IAM",
  "OPEN_BANKING",
  "PORTAL",
  "RULES_ENGINE",
  "SCORING_PROXY",
  "TASK",
  "TAX_NOTICE_ANALYSIS",
  "TAX_REPORT_ANALYSIS",
  "TRANSLATION",
  "SHORTENER",
  "CHECK_COMPANY",
  "UPLOAD",
  "TRACKETIO_ANALYSIS",
  "STORAGE",
  "ALERTING",
  "TRACKETIO",
  "OPEN_REVENUE",
  "ENRICHMENT",
  "FACE_MATCHING"
]);