<script setup lang="ts">
import {computed, ref} from "vue";
import Loader from "@/components/loader/Loader.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import VerificationResult from "@/components/VerificationResult.vue";
import XMasBonhomme from "@/components/x-mas/XMasBonhomme.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import {isArray, isNull} from "lodash";
import BoxOutputMultiRequests from "@/components/box-output/BoxOutputMultiRequests.vue";

const props = withDefaults(defineProps<{
  searchImage: Array<string>,
  customerProfile: Object,
  causesFraud: Object,
  company: string,
  phone: string,
  email: string,
  permissions: Array<string>,
  canScoring: boolean
}>(), {
  company: '',
  phone: '',
  email: '',
  canScoring: false
});

const searchImageModalFlag = ref(false);

const displayResult = (result: boolean) => {
  return result ? 'success-icon.png' : 'failed-icon.png';
};

const openSearchImage = () => {
  searchImageModalFlag.value = !searchImageModalFlag.value;
};

const phoneDetailed = computed(() => {
  return props.permissions?.includes('KIT_B2C_WEB_SCORE_DETAILS_PHONE');
});

const emailDetailed = computed(() => {
  return props.permissions?.includes('KIT_B2C_WEB_SCORE_DETAILS_MAIL');
});

const socialNetworksDetailed = computed(() => {
  return props.permissions?.includes('KIT_B2C_WEB_SCORE_DETAILS_SOCIAL_NETWORKS');
});

const hasNoImage = computed(() => {
  return props.searchImage?.length === 0;
});

const hasSocialNetWorksPhone = computed(() => {
  return isArray(props.causesFraud.social_networks.phone_list) && props.causesFraud.social_networks.phone_list.length > 0;
});

const hasSocialNetWorksEmail = computed(() => {
  return isArray(props.causesFraud.social_networks.email_list) && props.causesFraud.social_networks.email_list.length > 0;
});

const isSearchImage = computed(() => {
  return isArray(props.searchImage) && props.permissions?.includes('KIT_B2C_WEB_SCORE_DETAILS_SEARCH_IMAGE')
});

const displayMultiRequest = computed(() => {
  return (props.causesFraud.multi_requests.email === 0 && props.causesFraud.multi_requests.phone === 0) || props.causesFraud.multi_requests.iban === 0;
});

const displayFirstSeenDate = (data: string) => {
  return props.causesFraud && !isNull(data) ? new Date(data).toLocaleDateString() : '-';
}

const displayData = (type: string, data: string, dataSynthesis: string) => {
  if (type === 'phone') {
    return !isNull(data) && data !== '+33' ? data : dataSynthesis !== '' ? dataSynthesis : '-';
  } else if (type === 'email') {
    return !isNull(data) && data !== '' ? data : dataSynthesis !== '' ? dataSynthesis : '-';
  }
}
</script>
<template>
  <ContentCard v-if="!canScoring" :checked-display-content="false" :is-padlock="true" class="detailed-score"
               :title="translate('DETAILED_SCORE')"/>
  <template v-else>
    <ContentCard :title="translate('DETAILED_SCORE')" class="detailed-score">
      <Loader v-if="causesFraud == null"/>
      <div v-if="causesFraud != null" class="detailed-score_first-block">
        <ResultCard v-if="phoneDetailed" :title="translate('PHONE')" class="result-card-b2c">
          <div class="detailed-score-verification-result">
            <VerificationResult :value="causesFraud.contact.phone_exists" :label="translate('VERIFIED')"
                                :type="translate('PHONE')" :tooltip="translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')"
                                class="verification"/>
            <VerificationResult :value="causesFraud.contact.phone_name" :label="translate('LINKED')"
                                :type="translate('PHONE')" :tooltip="translate('TOOLTIP.MOBILE_PHONE_LINK')"
                                :is-warning="true"/>
          </div>
          <LabelValuePrinter :label="translate('FIRST_SEEN_DATE')"
                             :value="displayFirstSeenDate(causesFraud.contact.phone_first_seen_date)"/>
          <LabelValuePrinter :label="translate('TELEPHONE.NUMBER')"
                             :value="displayData('phone', customerProfile.phone.value, phone)"/>
          <LabelValuePrinter :label="translate('TELEPHONE.LINETYPE')" :value="causesFraud.contact.phone_linetype"/>
          <LabelValuePrinter :label="translate('TELEPHONE.GEOLOCATION')"
                             :value="causesFraud.contact.phone_geo_location"/>
          <LabelValuePrinter :label="translate('TELEPHONE.CARRIER')" :value="causesFraud.contact.phone_carrier">
            <img v-if="causesFraud.contact.phone_carrier_risky" src="../../../public/images/warning-icon.png"
                 class="warning-icon" alt="warning-icon.png"/>
          </LabelValuePrinter>
          <LabelValuePrinter :label="translate('TELEPHONE.ORIGINAL_CARRIER')"
                             :value="causesFraud.contact.phone_original_carrier">
            <img v-if="causesFraud.contact.phone_original_carrier_risky" src="../../../public/images/warning-icon.png"
                 class="warning-icon" alt="warning-icon.png" :title="translate('OPERATOR_AT_RISK')"/>
          </LabelValuePrinter>
        </ResultCard>
        <ResultCard v-if="emailDetailed" :title="translate('EMAIL')" class="result-card-b2c">
          <div class="detailed-score-verification-result">
            <VerificationResult :value="causesFraud.contact.email_exists" :label="translate('VERIFIED')"
                                :type="translate('EMAIL')" :tooltip="translate('TOOLTIP.EMAIL_VERIFICATION')"
                                class="verification"/>
            <VerificationResult :value="causesFraud.contact.email_name" :label="translate('LINKED')"
                                :type="translate('EMAIL')" :tooltip="translate('TOOLTIP.EMAIL_LINK')"
                                :is-warning="true"/>
          </div>
          <LabelValuePrinter :label="translate('FIRST_SEEN_DATE')"
                             :value="displayFirstSeenDate(causesFraud.contact.email_first_seen_date)"/>
          <LabelValuePrinter :label="translate('EMAIL')"
                             :value="displayData('email',customerProfile.email.value, email)">
            <img v-if="causesFraud.contact.email_disposable" src="../../../public/images/warning-icon.png"
                 class="warning-icon" alt="warning-icon.png"
                 :title="translate('TOOLTIP.EMAIL_DISPOSABLE')"/>
            <img v-if="causesFraud.contact.email_domain_risky" src="../../../public/images/warning-icon.png"
                 class="warning-icon" alt="warning-icon.png" :title="translate('TOOLTIP.EMAIL_RISKY')"/>
          </LabelValuePrinter>
        </ResultCard>
        <div class="detailed-score-forth-block">
          <ResultCard v-if="socialNetworksDetailed" :title="translate('SOCIAL_NETWORKS.TITLE')">
            <div class="detailed-score_social-networks">
              <div>
                <span class="detailed-score_social-networks-label">{{ translate("SOCIAL_NETWORKS.PHONE") }} : </span>
                <span>{{ causesFraud.social_networks.phone }}</span>
              </div>
              <ul v-if="hasSocialNetWorksPhone">
                <li v-for="(phone_network, key) in causesFraud.social_networks.phone_list" :key="key"
                    class="detailed-score_social-networks-result"> {{ phone_network }}
                </li>
              </ul>
              <div>
                <span class="detailed-score_social-networks-label">{{ translate("SOCIAL_NETWORKS.MAIL") }} : </span>
                <span>{{ causesFraud.social_networks.email }}</span>
              </div>
              <ul v-if="hasSocialNetWorksEmail">
                <li v-for="(mail_network, index) in causesFraud.social_networks.email_list" :key="index"
                    class="detailed-score_social-networks-result">{{ mail_network }}
                </li>
              </ul>
            </div>
          </ResultCard>
          <ResultCard v-if="isSearchImage"
                      :title="translate('SOCIAL_FACE_MATCHING.TITLE')" class="no-print">
            <div class="detailed-score-search-image">
              <Button :label="translate('BUTTONS.CONSULT')" :disabled="hasNoImage" @click="openSearchImage"/>
              <CustomModal v-model:open="searchImageModalFlag" :title-modal="translate('SOCIAL_FACE_MATCHING.TITLE')"
                           :display-close-button="true">
                <LabelValuePrinter class="facematching-images border"
                                   :label="translate('SOCIAL_FACE_MATCHING.CATCHED_IMAGES')" :value="searchImage.length"
                                   :tooltip-title="translate('TOOLTIP.SOCIAL_FACE_MATCHING')"/>
                <Carousel :images="searchImage"/>
              </CustomModal>
              <span v-if="hasNoImage">{{ translate('SOCIAL_FACE_MATCHING.NO_IMAGE') }}</span>
            </div>
          </ResultCard>
        </div>
      </div>
      <BoxOutputMultiRequests v-if="causesFraud != null && !displayMultiRequest" :result="causesFraud.multi_requests"
                              :company="company"
                              :title="translate('MULTI_REQUESTS.TITLE')" market-type="b2c"/>
    </ContentCard>
  </template>
</template>