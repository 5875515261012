import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const listCompanies = (excludeMeelo: boolean) =>
  api.get(`${envConfig.BACKEND_ROUTE}/iam/v5/internal/user/companies?excludeMeelo=${excludeMeelo}`)
    .then((response) => response.data);

export const listUsers = (companyId: string) =>
  api.get(`${envConfig.BACKEND_ROUTE}/iam/v5/internal/companies/${companyId}/users`)
    .then((response) => response.data);

export const goConnectAs = (userId: string) =>
  api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/me/connect-as/${userId}`)
    .then((response) => response.data);

export const logoutConnectAs = () => {
  return api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/me/connect-as/reset`)
}

export default {logoutConnectAs, goConnectAs, listUsers, listCompanies};

