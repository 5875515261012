<script setup lang="ts">
import {computed, onMounted, reactive, ref} from "vue";
import {useToast} from "vue-toast-notification";

import {resetPassword} from "@/apiCalls/passwordReset";
import {translate} from "@/i18n";
import {getTokenFromUrlParams} from "@/utils/getTokenFromUrlParams";

import GeneralCondition from "@/components/GeneralCondition.vue";
import MeeloLogo from "@/components/Logo.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import Button from "@/components/button/Button.vue";
import {useRouter} from "vue-router";

const toast = useToast();
const router = useRouter();

const inProgress = ref(false);
const isInit = ref(false);

const initialFormData = {
  newPassword: "",
  confirmPassword: "",
};

const formData = reactive({...initialFormData});

const isPasswordInvalid = computed(() => {
  return !!formData.newPassword
      ? !(
          formData.newPassword.length >= 8 &&
          /[A-Z]/.test(formData.newPassword) &&
          /[a-z]/.test(formData.newPassword) &&
          /[0-9]/.test(formData.newPassword) &&
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(formData.newPassword)
      )
      : false;
});

const isConfirmationInvalid = computed(() => {
  return !!formData.newPassword && !!formData.confirmPassword
      ? formData.newPassword !== formData.confirmPassword
      : false;
});

const formattedTitle = computed(() => {
  return isInit.value ? "RESET_PASSWORD_PAGE.INIT_TITLE" : "RESET_PASSWORD_PAGE.TITLE";
});

const submit = async () => {
  const token = getTokenFromUrlParams("reset_token");
  if (isPasswordInvalid.value || isConfirmationInvalid.value || !token) return;
  try {
    inProgress.value = true;
    await resetPassword(formData.newPassword, token);
    Object.assign(formData, initialFormData);
    inProgress.value = false;
    toast.success(translate("RESET_PASSWORD_PAGE.PASSWORD_RESET"));
    router.push("/login");
  } catch (error) {
    toast.clear();
    toast.error(translate("RESET_PASSWORD_PAGE.PASSWORD_RESET_ERROR"));
    inProgress.value = false;
  }
};

const getQueryParams = () => {
  // get the query param and set the isInit flag accordingly
  const urlParams = new URLSearchParams(window.location.search);
  isInit.value = urlParams.get("init") === "true";
}

onMounted(() => {
  getQueryParams();
});
</script>

<template>
  <div class="LoginPage">
    <div class="LoginPage__left">
      <a :href="'/login'" class="LoginPage__left__link">
        <MeeloLogo class="LoginPage__left__logo"/>
      </a>
      <a href="https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/" target="_blank">
        <img class="logoB" src="/images/bcorpLogo.png" alt="LogoBCorp"/>
      </a>
      <GeneralCondition class="LoginPage__left__cgu"/>
    </div>
    <div class="LoginPage__right">
      <MeeloLogo class="LoginPage__right__logo" white/>
      <form @submit.prevent="submit" class="password-reset-form">
        <h2>{{ translate(formattedTitle) }}</h2>
        <PasswordInput
            :label="translate('RESET_PASSWORD_PAGE.NEW_PASSWORD')"
            required
            v-model:password="formData.newPassword"
        />
        <div class="password-validation-error" v-show="isPasswordInvalid">
          <span>{{ translate("RESET_PASSWORD_PAGE.INVALID_PASSWORD") }}</span>
        </div>
        <PasswordInput
            :label="translate('RESET_PASSWORD_PAGE.CONFIRM_PASSWORD')"
            required
            v-model:password="formData.confirmPassword"
        />
        <div class="password-confirmation-error" v-show="isConfirmationInvalid">
          <span>{{ translate("RESET_PASSWORD_PAGE.PASSWORD_NOT_MATCH") }}</span>
        </div>
        <Button
            type="submit"
            class="submit-button"
            :label="translate(formattedTitle)"
            :loading="inProgress"
        />
      </form>
      <GeneralCondition is-right class="LoginPage__right__cgu" white/>
    </div>
  </div>
</template>
