<template>
  <div :class="`Logo ${white ? 'Logo__white' : ''}`">
    <img :src="white ? '/images/official_logo_white.png':'/images/official_logo.png'"
         alt="Meelo Official Logo"/>
    <span>portal</span>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'MeeloLogo',
  props: {
    white: {
      type: Boolean,
      default: false
    }
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

</style>
