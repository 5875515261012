<script setup lang="ts">
import {isEmpty} from "lodash";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "vue-toast-notification";
import {useStore} from "vuex";

import {createJourneyIdByTask, getData} from "@/apiCalls/taskApi";
import {
  DOCUMENTS_TIMEOUT,
  DocumentTypeDto,
  allUploadedFilesOcrDone,
  createIframeUploadDocument, isAuthenticityDone,
} from "@/apiCalls/uploadDocumentsApi";
import {translate} from "@/i18n";
import {TextInputType, getRegex} from "@/types/TextInputType";
import {handleUpload} from "@/utils/documentAnalysis";
import {DOCUMENTS_FIELDS} from "@/utils/dtoFields";

import BoxOutputDoc from "@/components/BoxOutputDoc.vue";
import DocumentTypeSelector from "@/components/DocumentTypeSelector.vue";
import Button from "@/components/button/Button.vue";
import PhoneInput from "@/components/input/PhoneInput.vue";
import Loader from "@/components/loader/Loader.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import PrintFolderInfo from "@/components/print/shared/PrintFolderInfo.vue";
import {sleep} from "@/utils/sleep";
import {usePermissions} from "@/composables/usePermissions";
const props = withDefaults(
    defineProps<{ showTitle: boolean; siren?: string }>(),
    {
      showTitle: true,
      siren: "",
    }
);

const emit = defineEmits(["loadTaxReportResult", "startLoading"]);

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const permissions = usePermissions();


const contextData = ref({});
const iframeLink = ref("");
const selectedDocCategory = ref<"ID" | "OTHER">();
const uploadMode = ref<"PHONE" | "IFRAME">("IFRAME");
const phoneNumber = ref("+33");
const showModal = ref(false);
const isLoadingIframe = ref(false);
const isLoadingOcrResponse = ref(false);
const isLoadingIdOcrResponse = ref(false);
const isLoadingOtherDocOcrResponse = ref(false);
const isAuthenticity = ref(false);
const canSelectDocsType = ref(true);
const selectedDocTypes = ref<DocumentTypeDto[]>([]);
const timer = ref<NodeJS.Timeout>();

const journeyId = computed(() => {
  const query = router.currentRoute.value.query.journeyId;
  return query ? query.toString() : "";
});

const permissionsSorted = computed(() => store.getters.permissionsSorted);

const showUploadDocumentBlock = computed(() => {
  return permissions.docPermissions('UPLOAD_UPLOAD');
});

const permissionKitPath = computed(() => {
  const path = route.path;
  if (path.startsWith("/analysis-b2b")) return "KIT_B2B_RESTITUTION";
  if (path.startsWith("/analysis-b2c")) return "KIT_B2C_RESTITUTION";
  return "KIT_DOC_RESTITUTION";
});

const showRestitutionDocumentBlock = computed(() => {
  const path = permissionKitPath.value;
  return permissionsSorted.value?.some(
      (elt: string | string[]) =>
          elt.includes(`${path}_DETAILS`) ||
          elt.includes(`${path}_SOFT`)
  );
});

const phoneValid = computed(() => {
  return !getRegex(TextInputType.PHONE_NUMBER).test(phoneNumber.value);
});

const permissionKitPathLabel = computed(() => {
  const path = route.path;
  if (path.startsWith("/analysis-b2b")) return "KIT_B2B_UPLOAD";
  if (path.startsWith("/analysis-b2c")) return "KIT_B2C_UPLOAD";
  return "KIT_DOC_UPLOAD";
});

const hasDocumentPermission = (docType: string): boolean => {
  return permissionsSorted.value?.includes(
      `${permissionKitPathLabel.value}_${docType}`
  );
};

const documentType = computed(() => {
  return [
    {
      label: translate("DOCUMENT_CONTROL.RIB"),
      value: "BANK_DETAILS",
      isPermitted: hasDocumentPermission("RIB"),
    },
    {
      label: translate("DOCUMENT_CONTROL.KBIS_SELECT"),
      value: "KBIS",
      isPermitted: hasDocumentPermission("KBIS"),
    },
    {
      label: translate("DOCUMENT_CONTROL.TAX_REPORT"),
      value: "TAX_REPORT",
      isPermitted: hasDocumentPermission("TAX_REPORT"),
    },
    {
      label: translate("DOCUMENT_CONTROL.TAX_NOTICE_SELECT"),
      value: "TAX_NOTICE",
      isPermitted:
          hasDocumentPermission("TAX_NOTICE_INCOME") &&
          hasDocumentPermission("TAX_NOTICE_PROPERTY"),
    },
    {
      label: translate("DOCUMENT_CONTROL.PAYSLIP"),
      value: "PAYSLIP",
      isPermitted: hasDocumentPermission("PAYSLIP"),
    },
  ];
});

const canUploadIdCheckDoc = computed(() => hasDocumentPermission("ID_CHECK"));

const sendSms = async () => {
  if (selectedDocCategory.value === "ID") {
    await createIframeUploadDocument(
        journeyId.value,
        "ID_CHECK",
        "PHONE",
        phoneNumber.value
    );
    phoneNumber.value = "";
    setTimeout(() => {
      showModal.value = false;
    }, 2000);
    emit("startLoading", "ID_CHECK");
  }
};

const addAuthenticityUploadMode = () => {
  if (canSelectDocsType.value) {
    if (!hasDocumentPermission("AUTHENTICITY")) return;
    isAuthenticity.value = !isAuthenticity.value;
    if (isAuthenticity.value) {
      selectedDocTypes.value.push("OTHER");
    } else {
      selectedDocTypes.value = selectedDocTypes.value.filter(
          (docType) => docType !== "OTHER"
      );
    }
  }
};

const openModal = (category: "ID" | "OTHER") => {
  selectedDocCategory.value = category;
  iframeLink.value = "";
  selectedDocTypes.value = [];
  showModal.value = true;
  isAuthenticity.value = false;
  isLoadingIframe.value = false;
  canSelectDocsType.value = true;
  uploadMode.value = "IFRAME";
  phoneNumber.value = "+33";
};

const setBoxLoading = (
    category: "ID" | "OTHER" | undefined,
    value: boolean
) => {
  if (!category) isLoadingOcrResponse.value = value;
  if (category === "ID") {
    isLoadingIdOcrResponse.value = value;
  } else {
    isLoadingOtherDocOcrResponse.value = value;
  }
};

const generateJourneyId = async () => {
  isLoadingIframe.value = true;
  const journey = await createJourneyIdByTask(
      "meelo-portal",
      "document-analysis"
  );
  isLoadingIframe.value = false;
  return journey.id;
};

const uploadDocument = async (
    uploadMode: "PHONE" | "IFRAME",
    category: "ID" | "OTHER"
) => {
  canSelectDocsType.value = false;
  let _journeyId = journeyId.value;
  if (!_journeyId) {
    _journeyId = await generateJourneyId();
    router.push({
      query: {journeyId: _journeyId},
    });
  }
  isLoadingIframe.value = true;
  iframeLink.value = await handleUpload({
    documentCategory: category,
    journeyId: _journeyId,
    uploadMode,
    documentTypes: category === "OTHER" ? selectedDocTypes.value : [],
  });
  isLoadingIframe.value = false;
};

const uploadIDDoc = async (uploadMode: "PHONE" | "IFRAME") => {
  openModal("ID");
  await uploadDocument(uploadMode, "ID");
};

const fetchOCR = async (isOnMounted = false) => {
  const {data} = await getData(journeyId.value, DOCUMENTS_FIELDS);
  contextData.value = data.data;
  if (
      !isOnMounted &&
      contextData.value &&
      Object.keys(contextData.value).includes("tax_reports_result") &&
      // @ts-ignore
      !isEmpty(contextData.value["tax_reports_result"])
  ) {
    if (props.siren) {
      // @ts-ignore
      const taxReportData = contextData.value["tax_reports_result"].filter(
          // @ts-ignore
          (el) => el.context.siren === props.siren
      );
      emit("loadTaxReportResult", taxReportData);
    }
    // @ts-ignore
  }

  if (!isEmpty(contextData.value) && allUploadedFilesOcrDone(contextData.value)) {
    setBoxLoading(selectedDocCategory.value, false);
  }

  if (!isEmpty(contextData.value) && !isAuthenticityDone(contextData.value)) {
    await sleep(10 * 1000);
    await fetchOCR(false);
  }
};

const loadOCRResult = () => {
  const documentTimeOut =
      selectedDocCategory.value === "ID"
          ? DOCUMENTS_TIMEOUT["ID_CHECK"]
          : isAuthenticity.value
              ? DOCUMENTS_TIMEOUT["OTHER"]
              : 45;
  toast.clear()
  toast.success(translate("UPLOAD_FINISHED"));
  setTimeout(() => {
    showModal.value = false;
  }, 2000);
  timer.value = setInterval(async () => {
    await fetchOCR();
  }, 15000);
  setTimeout(() => {
    clearInterval(timer.value);
  }, documentTimeOut * 1000);
};

onMounted(async () => {
  if (journeyId.value) {
    setBoxLoading(selectedDocCategory.value, true);
    await fetchOCR(true);
  }
  window.addEventListener("message", async (e: MessageEvent) => {
    if (e.data === "OCR_DONE") {
      setBoxLoading(selectedDocCategory.value, true);
      await loadOCRResult();
    }
  });
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<template>
  <div class="document-analysis">
    <h5 v-if="showTitle" class="title">
      {{ translate("SIDEBAR.DOCUMENT_ANALYSIS") }}
    </h5>
    <div class="document-analysis-box-container">
      <PrintFolderInfo
          :entries="{
          uniqId: journeyId,
        }"
      />
      <BoxOutputDoc
          @new-analysis="() => uploadIDDoc('IFRAME')"
          :desc="translate('DOCUMENT_CONTROL.ID_ANALYSIS_DESCRIPTION')"
          :is-loading="isLoadingIdOcrResponse || isLoadingOcrResponse"
          :context-data="contextData"
          :can-upload-document="canUploadIdCheckDoc && showUploadDocumentBlock"
          :can-restitute-document="showRestitutionDocumentBlock"
          title="DOCUMENT_CONTROL.ID_ANALYSIS_TITLE"
          doc-category="ID"
          :can-show="!isLoadingOcrResponse"
      />
      <BoxOutputDoc
          @new-analysis="() => openModal('OTHER')"
          :desc="translate('DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_DESCRIPTION')"
          :is-loading="isLoadingOtherDocOcrResponse || isLoadingOcrResponse"
          :context-data="contextData"
          :can-upload-document="showUploadDocumentBlock"
          :can-restitute-document="showRestitutionDocumentBlock"
          doc-category="OTHER"
          title="DOCUMENT_CONTROL.DOCUMENT_ANALYSIS_TITLE"
          :can-show="!isLoadingOcrResponse"
      />
    </div>
  </div>
  <CustomModal custom-class="web-upload-modal" v-model:open="showModal">
    <div class="document-analysis-modal" v-if="selectedDocCategory">
      <h5>
        {{
          translate(
              `DOCUMENT_CONTROL.TABLE.${
                  selectedDocCategory === "ID"
                      ? "ID_ANALYSIS_TITLE"
                      : "DOCUMENT_ANALYSIS_TITLE"
              }`
          )
        }}
      </h5>
      <DocumentTypeSelector
          v-if="selectedDocCategory === 'OTHER'"
          v-model="selectedDocTypes"
          :options="documentType"
          :can-select="canSelectDocsType"
      >
        <template #options>
          <div
              @click="addAuthenticityUploadMode"
              class="document-type-selector-option"
              :class="{ selected: isAuthenticity }"
          >
            {{ translate("DOCUMENT_CONTROL.OTHER_DOCUMENT") }}
            <img
                v-if="!hasDocumentPermission('AUTHENTICITY')"
                :src="require('/public/images/padlock-blue.png')"
                alt=""
            />
          </div>
        </template>
      </DocumentTypeSelector>
      <Button
          v-if="selectedDocCategory === 'ID'"
          :label="
          translate(
            `BUTTONS.${
              uploadMode === 'IFRAME' ? 'SEND_TEXT_MESSAGE' : 'IFRAME'
            }`
          )
        "
          @click="
          uploadMode === 'IFRAME'
            ? (uploadMode = 'PHONE')
            : (uploadMode = 'IFRAME')
        "
          class="upload-iframe-button"
      />
      <Loader v-if="uploadMode === 'IFRAME' && isLoadingIframe"/>
      <iframe
          v-if="uploadMode === 'IFRAME' && iframeLink"
          :src="iframeLink"
          id="upload-iframe"
      />
      <div
          v-if="selectedDocCategory === 'ID' && uploadMode == 'PHONE'"
          class="verification-document__phone"
      >
        <PhoneInput v-model:phoneValue="phoneNumber"/>
        <Button
            class="container-openbanking-informations-form-submit-button"
            type-button="submit"
            :label="translate('BUTTONS.VALIDATE_AND_SEND_SMS')"
            v-on:click="sendSms"
            :disabled="phoneValid"
        />
      </div>
      <Button
          v-if="selectedDocCategory === 'ID'"
          type="submit"
          :label="translate('BUTTONS.CLOSE')"
          @click="showModal = false"
      />
      <template v-else>
        <Button
            v-if="selectedDocTypes.length > 0 && !iframeLink"
            type="submit"
            :label="translate('BUTTONS.NEXT')"
            @click="() => uploadDocument('IFRAME', 'OTHER')"
        />
        <Button
            v-else
            type="submit"
            :label="translate('BUTTONS.CLOSE')"
            @click="showModal = false"
        />
      </template>
    </div>
  </CustomModal>
</template>
