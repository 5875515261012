import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const checkIbanNaturalPerson = (
  journeyId: string,
  lastName: string,
  firstName: string,
  birthdate: any,
  iban: string
) =>
  api
    .post(
      `${envConfig.CHECK_VALUE_ROUTE}/v1/check/iban/natural/person?journeyId=${journeyId}&language=FR`,
      {
        firstname: firstName,
        lastName: lastName,
        birthdate: birthdate,
        iban: iban.replaceAll(" ",""),
      }
    )
    .then((response) => response);

export const checkIbanCompany = (
  journeyId: string,
  data: {
    siren?: string;
    iban?: string;
  }
) =>
  api
    .post(
      `${envConfig.CHECK_VALUE_ROUTE}/v1/check/iban/company?journeyId=${journeyId}&language=FR`,
      data
    )
    .then((response) => response);

export const getBankName = async (iban: string) => {
  return await api
    .get(`${envConfig.BACKEND_ROUTE}/open-data/v1/bank/search?iban=${iban.replaceAll(" ","")}`);
};
