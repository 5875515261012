<script setup lang="ts">
import {reactive} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import Loader from "@/components/loader/Loader.vue";
import CopyToClipboard from "@/components/copy-to-clipboard/CopyToClipboard.vue";

export interface ClientForm {
  email: { value: string, type: string };
  phone: { value: string, type: string };
}


export interface Props {
  enrichment: Object;
  loader: boolean;
  canEnrichment: boolean;
  clientForm: Object;
}

const props = withDefaults(defineProps<Props>(), {
  loader: false,
  canEnrichment: false
});

// reactive array of type ClientForm
const clientFormData = reactive<ClientForm>(<ClientForm>props.clientForm);

const isMatchedEmail = (str: string) => {
  return clientFormData.email.value === str;
};

const isMatchedPhone = (str: string) => {
  // remove spaces of both values
  return clientFormData.phone.value.replace(/\s/g, '') === str.replace(/\s/g, '');
};

const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return ""
  return phoneNumber.replaceAll(" ", "").replaceAll(/(.{3})(.{1})(.{2})(.{2})(.{2})(.{2})/g, "$1 $2 $3 $4 $5 $6")
}
</script>

<template>
  <ContentCard v-if="!canEnrichment" :title="translate('ENRICHMENT.TITLE')" class="enrichment-linkedin"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard
      :title="translate('ENRICHMENT.TITLE')"
      class="enrichment-linkedin"
    >
      <Loader v-if="loader"/>
      <div v-else>
        <div
          v-if="Object.keys(enrichment).length === 0 || enrichment?.profile === null || !(Object.keys(enrichment).includes('profile'))"
          class="enrichment-no-data">
          <span>{{ translate('ERRORS.NO_DATA') }}</span>
        </div>
        <div v-else class="enrichment-information-container">
          <span class="enrichment-information-container__title">{{ translate("ENRICHMENT.TITLE_INFO") }}</span>
          <a :href="`https://www.linkedin.com/in/${enrichment.profile.id}`" target="_blank">
            <span>{{ enrichment.profile.name }}</span>
          </a>
          <div v-if="!enrichment.profile">
            <span>{{ translate("ENRICHMENT.NOPROFIL") }}</span>
          </div>
          <div v-else>
            <div
              v-if="
              enrichment.profile.emails.length == 0 &&
              enrichment.profile.phones.length == 0
            "
              class="enrichment-information-container__no-information"
            >
              <span>{{ translate("ENRICHMENT.NOCORDINATE") }}</span>
            </div>
            <div v-else class="enrichment-information-container__information">
              <div
                v-if="enrichment.profile.phones.length > 0"
                class="enrichment-information-container__block-phone"
              >
                <span>{{ translate("ENRICHMENT.PHONE") }}</span>
                <span
                  v-for="phones in enrichment.profile.phones"
                  :key="phones.phone"
                  class="enrichment-information-container__email-phone"
                >
                  <ul>
                  <li>
                    {{ formatPhoneNumber(phones.phone) }}
                    <CopyToClipboard
                      class="enrichment-information-container__copy"
                      :value="phones.phone"
                    />
                    <img v-if="isMatchedPhone(phones.phone)" class="enrichment-information-container__matched"
                         src="/images/puzzle.png" :title="translate('ENRICHMENT.MATCH.PHONE')"/>
                  </li>
                  </ul>
              </span>
              </div>
              <div v-else class="enrichment-information-container__block-phone">
                <span>{{ translate("ENRICHMENT.NO_PHONE") }}</span>
              </div>
              <div
                v-if="enrichment.profile.emails.length > 0"
                class="enrichment-information-container__email"
              >
                <div
                  v-if="
                  enrichment.profile.emails.filter((email) => email.type === 'DIRECT')
                    .length > 0
                "
                >
                  <span>{{ translate("ENRICHMENT.EMAIL_PERSO") }}</span>
                  <ul>
                    <li
                      v-for="emails in enrichment.profile.emails.filter(
                      (email) => email.type === 'DIRECT'
                    )"
                      :key="emails.email"
                    >
                      {{ emails.email }}
                      <CopyToClipboard
                        class="enrichment-information-container__copy"
                        :value="emails.email"
                      />
                      <img v-if="isMatchedEmail(emails.email)" class="enrichment-information-container__matched"
                           src="/images/puzzle.png" :title="translate('ENRICHMENT.MATCH.EMAIL_PERSO')"/>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                  enrichment.profile.emails.filter((emails) => emails.type === 'WORK')
                    .length > 0
                "
                >
                  <span>{{ translate("ENRICHMENT.EMAIL_PRO") }}</span>
                  <ul>
                    <li
                      v-for="emails in enrichment.profile.emails.filter(
                      (emails) => emails.type === 'WORK'
                    )"
                      :key="emails.email"
                    >
                      {{ emails.email }}
                      <CopyToClipboard
                        class="enrichment-information-container__copy"
                        :value="emails.email"
                      />
                      <img v-if="isMatchedEmail(emails.email)" class="enrichment-information-container__matched"
                           src="/images/puzzle.png" :title="translate('ENRICHMENT.MATCH.EMAIL_PRO')"/>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="enrichment-information-container__email">
                <span>{{ translate("ENRICHMENT.NO_EMAIL") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentCard>
  </template>
</template>
