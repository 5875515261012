<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import TrafficLight from "@/components/traffic-light/TrafficLight.vue";
import {translate} from "@/i18n";
import Gauge from "@/components/gauge/Gauge.vue";
import {onMounted, reactive, ref} from "vue";
import Button from "@/components/button/Button.vue";
import {isNil} from "lodash";
import {causesCreditSafeType} from "@/types/creditSafe";

const props = withDefaults(
    defineProps<{
      causesCreditSafe: causesCreditSafeType,
      isCompanyFR?: boolean,
      isDataLoading?: boolean,
      isError?: boolean,
    }>(), {
      isCompanyFR: true,
      isDataLoading: false,
      isError: false,
    });

const textColor = ref('#000000');
const colors = ref(['#eeeef1', '#eeeef1', '#eeeef1']);
const paymentTrend = ref({value: 0, title: "", display: false});
//need to declare the presences like that to keep the order of the model :
const presenceOf = ref([
  {name: 'presenceOfJudgment', key: '', value: 'NC', display: true},
  {name: 'presenceOfPrivilege', key: '', value: 'NC', display: true},
  {name: 'presenceOfSanction', key: '', value: 'NC', display: true}]);
const stickerValues = reactive({
  label: translate("NEW"),
});

const scoreList = {
  'A': {color: '#36ba75', risk: 'WEAK', colorIndex: 2, checkDescription: false},
  'B': {color: '#36ba75', risk: 'WEAK', colorIndex: 2, checkDescription: false},
  'C': {color: '#ffa543', risk: 'MODERATE', colorIndex: 1, checkDescription: false},
  'D': {color: '#ffa543', risk: 'MODERATE', colorIndex: 1, checkDescription: false},
  'E': {color: '#ff7676', risk: 'HIGH', colorIndex: 0, checkDescription: true},
};

const paymentTrendList = {
  'WORSENING': {value: 0.125, title: 'WORSENING', display: true},
  'IMPROVING': {value: 0.875, title: 'IMPROVING', display: true},
  'STABLE': {value: 0.5, title: 'STABLE', display: true},
  'NOT_AVAILABLE': {value: 0, title: 'NOT_AVAILABLE', display: false},
  'null': {value: 0, title: 'NO_DATA', display: false}
};


const isFinancialRisk = () => {
  let defaultText = translate('SCORE_CREDIT_SAFE.RISK.NOT_SCORED');
  const {value, description} = props.causesCreditSafe.internationalScore;
  const score = scoreList[value as keyof typeof scoreList];
  if (score && !score.checkDescription || (score.checkDescription && description !== "Not Rated")) {
    textColor.value = score.color;
    colors.value[score.colorIndex] = score.color;
    return translate(`SCORE_CREDIT_SAFE.RISK.${score.risk}`);
  }
  return defaultText;
};

const redirection = () => {
  window.open(props.causesCreditSafe.redirectUrl ? props.causesCreditSafe.redirectUrl : 'https://app.creditsafe.com/companies/', '_blank');
}

const dataForTrafficLight = () => {
  const presenceOfData = props.causesCreditSafe;
  const presenceOfKeys = Object.keys(presenceOfData).filter(key => key.includes('presenceOf'));
  presenceOfKeys.forEach(key => {
    const idx = presenceOf.value.findIndex(presence => presence.name === key);
    if (idx !== -1) {
      presenceOf.value[idx].key = presenceOf.value[idx].name.toUpperCase();
      presenceOf.value[idx].value = isNil(presenceOfData[key as keyof typeof presenceOfData])
          ? 'NC'
          : presenceOfData[key as keyof typeof presenceOfData]
              ? translate('YES')
              : translate('NO');
      presenceOf.value[idx].display = props.isCompanyFR ? true : !(key === 'presenceOfPrivilege' || key === 'presenceOfSanction');
    }
  });
}

const dataForGauge = () => {
  const result = paymentTrendList[props.causesCreditSafe.paymentTrend as keyof typeof paymentTrendList];
  paymentTrend.value = {
    value: result.value,
    title: result.title,
    display: result.display
  };
}

onMounted(() => {
  dataForTrafficLight();
  dataForGauge();
});
</script>

<template>
  <ContentCard class="box-output-score-credit-safe" :title="translate('MODULE.FINANCIAL_RISK')"
               :sticker="stickerValues">
    <div class="box-output-score-credit-safe-elements">
      <div class="traffic-light-box">
        <span class="box-output-score-credit-safe-elements_title">{{
            translate('SCORE_CREDIT_SAFE.RISK_TITLE')
          }}</span>
        <TrafficLight
            :colors="colors"/>
        <span class="box-output-score-credit-safe-elements_text">{{
            translate(`${isFinancialRisk()}`)
          }}</span>
      </div>
      <div class="gauge-box">
        <span class="box-output-score-credit-safe-elements_title">{{
            translate('SCORE_CREDIT_SAFE.PAYMENT_TREND_TITLE')
          }}</span>
        <Gauge v-if="paymentTrend.display"
               :title="translate(`SCORE_CREDIT_SAFE.PAYMENT_TREND.${paymentTrend.title}`)"
               :show-title="true"
               :value="paymentTrend.value"/>
        <span v-else class="gauge-box_no-data">{{
            translate(`SCORE_CREDIT_SAFE.PAYMENT_TREND.${paymentTrend.title}`)
          }}</span>
      </div>
    </div>
    <div class="further-information">
      <div class="further-information_block">
        <span class="further-information_title">{{ translate('SCORE_CREDIT_SAFE.OTHER_INFORMATION') }}</span>
        <span v-for="presence in presenceOf" class="further-information_block_text">{{
            presence.display ? `${translate(`SCORE_CREDIT_SAFE.${presence.key}`)} : ${presence.value}` : ''
          }}</span>
      </div>
      <Button class="further-information_button" @click="redirection" :action-icon="'creditsafe-logo.png'"/>
    </div>
    <div class="headband">
      <span class="headband_text">{{
          translate('SCORE_CREDIT_SAFE.HEADBAND')
        }}</span>
    </div>
  </ContentCard>
</template>

<style lang="scss">
.box-output-score-credit-safe-elements_text {
  color: v-bind(textColor);
}
</style>
