import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import login from "@/apiCalls/login";

import OpenBanking from "@/components/blocks/OpenBanking.vue";
import AccountPage from "@/views/AccountPage.vue";
import AnalysisB2B from "@/views/AnalysisB2B.vue";
import AnalysisB2C from "@/views/AnalysisB2C.vue";
import BackOffice from "@/views/backoffice/BackOffice.vue";
import ManageGroups from "@/views/backoffice/ManageGroups.vue";
import ManageLists from "@/views/backoffice/ManageLists.vue";
import ManagePlaces from "@/views/backoffice/ManagePlaces.vue";
import ManageProfileAndAccess from "@/views/backoffice/ManageProfileAndAccess.vue";
import ManageUsers from "@/views/backoffice/ManageUsers.vue";
import MonitoringMyCompany from "@/views/backoffice/MonitoringMyCompany.vue";
import RulesEngineRules from '@/views/backoffice/RulesEngineRules.vue';
import RulesEngineSets from '@/views/backoffice/RulesEngineSets.vue';
import FraudDeclaration from "@/views/FraudDeclaration.vue";
import MonitoringMyUsage from "@/views/backoffice/MonitoringMyUsage.vue";
import UserCard from "@/views/backoffice/UserCard.vue";
import Batch from "@/views/Batch.vue";
import DocumentAnalysis from "@/views/DocumentAnalysis.vue";
import HistoricalPage from "@/views/HistoricalPage.vue";
import Home from "@/views/HomePage.vue";
import LoginForm from "@/views/login/LoginForm.vue";
import LoginPage from "@/views/login/LoginPage.vue";
import TwoFactorAuthenticationForm from "@/views/login/TwoFactorAuthenticationForm.vue";
import TwoFactorAuthenticationSetupForm from "@/views/login/TwoFactorAuthenticationSetupForm.vue";
import LogoutPage from "@/views/LogoutPage.vue";
import PasswordForgotPage from "@/views/password/PasswordForgotPage.vue";
import PasswordResetPage from "@/views/password/PasswordResetPage.vue";
import Recovery from "@/views/Recovery.vue";
import Synthesis from "@/views/Synthesis.vue";
import MyCompany from "@/views/backoffice/MyCompany.vue";
import MyConsumption from "@/views/MyConsumption.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: LoginPage,
    children: [
      {
        path: "",
        name: "LoginPage",
        component: LoginForm,
      },
      {
        path: "2fa",
        name: "Login2FAuthenticationPage",
        component: TwoFactorAuthenticationForm,
        props: () => ({
          authenticationMethod: login.authenticate2FA,
        }),
      },
      {
        path: "2fa/setup",
        name: "Login2FAuthenticationSetupPage",
        component: TwoFactorAuthenticationSetupForm,
        props: () => ({
          isLogin: true,
        }),
      },
      {
        path: "2fa/setup/validate",
        name: "Login2FAuthenticationSetupValidationPage",
        component: TwoFactorAuthenticationForm,
        props: () => ({
          authenticationMethod: login.validate2FAsetup,
        }),
      },
    ],
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordResetPage,
  },
  {
    path: "/forgot-password",
    name: "PasswordForgot",
    component: PasswordForgotPage,
  },
  {
    path: "/analysis-b2b",
    name: "AnalysisB2B",
    component: AnalysisB2B,
  },
  {
    path: "/analysis-b2c",
    name: "AnalysisPageB2C",
    component: AnalysisB2C,
  },
  {
    path: "/open-banking",
    name: "OpenBanking",
    component: OpenBanking,
  },
  {
    path: "/document-analysis",
    name: "DocumentAnalysis",
    component: DocumentAnalysis,
  },
  {
    path: "/historical",
    name: "HistoricalPage",
    component: HistoricalPage,
  },
  {
    path: "/synthesis",
    name: "Synthesis",
    component: Synthesis
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  {
    path: "/account",
    name: "AccountPage",
    component: AccountPage,
    props: () => ({
      authenticationMethod: login.validate2FAsetup,
      isLogin: false,
    }),
  },
  {
    path: "/batch",
    name: "Batch",
    component: Batch,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: Recovery,
  },
  {
    path: "/consumption",
    name: "MyConsumption",
    component: MyConsumption,
  },
  {
    path: "/back-office/home",
    name: "BackOffice",
    component: BackOffice,
  },
  {
    path: "/back-office/users",
    name: "ManageUsers",
    component: ManageUsers,
  },
  {
    path: "/back-office/users/:userId/card",
    name: "UserCard",
    component: UserCard,
  },
  {
    path: "/back-office/users/groups",
    name: "ManageGroups",
    component: ManageGroups,
  },
  {
    path: "/back-office/users/places",
    name: "ManagePlaces",
    component: ManagePlaces,
  },
  {
    path: "/back-office/lists",
    name: "ManageLists",
    component: ManageLists,
  },
  {
    path: "/back-office/my-usage",
    name: "MonitoringMyUsage",
    component: MonitoringMyUsage,
  },
  {
    path: "/back-office/usage-company",
    name: "MonitoringMyCompany",
    component: MonitoringMyCompany,
  },
  {
    path: '/back-office/users/profiles',
    name: 'ManageProfileAndAccess',
    component: ManageProfileAndAccess
  },
  {
    path: '/back-office/rules-engine/rules',
    name: 'RulesEngineRules',
    component: RulesEngineRules
  },
  {
    path: '/back-office/rules-engine/sets',
    name: 'RulesEngineSets',
    component: RulesEngineSets
  },
  {
    path: '/fraud-declaration',
    name: 'FraudDeclaration',
    component: FraudDeclaration
  },
  {
    path: '/back-office/my-company',
    name: 'MyCompany',
    component: MyCompany
  }
];

export default createRouter({
  history: createWebHistory(),
  routes
});
