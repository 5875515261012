import moment from "moment";
import {locale as i18nLocale, translate} from '@/i18n';
import {formatLocaleDateTimeUTC} from "@/utils/dateTime";
import {Router} from "vue-router";
import {size, values} from "lodash";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/it';
import 'moment/locale/de';
import Notifications from "@/components/header/Notifications.vue";

// constants
export const TIMEOUT = 7000;
export const INTERVAL = 60000;

// data
export enum NotificationFilter {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  OLDER = 'OLDER'
}

export enum NotificationServices {
  OPEN_BANKING = 'OPEN_BANKING',
  DOCUMENT_PROXY = 'DOCUMENT_PROXY',
  CHECK_COMPANY = 'CHECK_COMPANY',
  CHECK_IBAN = 'CHECK_IBAN',
  SCORING_PROXY = 'SCORING_PROXY'
}

export const notificationTypes: string[] = ['today', 'yesterday', 'older'];
export const confirmAction = () => translate('NOTIFICATIONS.ACTIONS.CONFIRM');
export const cancelAction = () => translate('NOTIFICATIONS.ACTIONS.CANCEL');

export interface Notification {
  id: string;
  scope?: string;
  type?: string;
  status?: string;
  service?: string;
  title: string;
  timestamp?: string;
  message: string;
  journeyId?: string;
  taskId?: string;
  businessId?: string;
  viewed?: boolean;
  createdAt?: Date;
  isToday?: boolean;
  isYesterday?: boolean;
  isOlder?: boolean;
  isRead?: boolean;
  isNew?: boolean;
}

export interface NotificationsSet {
  today: Notification[];
  yesterday: Notification[];
  older: Notification[];

  [key: string]: Notification[];
}

moment.locale(i18nLocale);

// methods & notifications utils

export const setNotifications = (notifications: Notification[], newNotifications: NotificationsSet, readNotifications: NotificationsSet) => {
  for (const filter of values(NotificationFilter)) {
    const filteredNotifications = filterNotificationsByType(notifications, filter);
    newNotifications[filter.toLowerCase()] = filteredNotifications.filter(n => !n.viewed);
    readNotifications[filter.toLowerCase()] = filteredNotifications.filter(n => n.viewed);
  }
}

export const userNotificationRouter = async (notification: Notification, router: Router) => {
  if (!notification.journeyId) return;
  const destination: string = 'synthesis';
  const route: string = `/${destination}?journeyId=${notification.journeyId}`;
  // redirect user to a new page
  window.open(router.resolve(route).href, '_blank');
};

export const markNotificationAsRead = (notification: Notification, newNotifications: NotificationsSet, readNotifications: NotificationsSet, type: string) => {
  const index: number = newNotifications[type].findIndex(n => n.id === notification.id);
  if (index !== -1) {
    newNotifications[type].splice(index, 1);
    readNotifications[type].push(notification);
  }
}

export const markAllNotificationsAsRead = (newNotifications: NotificationsSet, readNotifications: NotificationsSet): string[] => {
  const ids: string[] = [];
  for (const type of notificationTypes) {
    newNotifications[type].forEach(notification => notification.viewed = true);
    const typeIds = newNotifications[type].map(notification => notification.id);
    ids.push(...typeIds);
    readNotifications[type] = readNotifications[type].concat(newNotifications[type]);
    newNotifications[type] = [];
  }
  return ids;
}

export const markNotificationAsDeleted = (id: string, readNotifications: NotificationsSet) => {
  for (const type of notificationTypes) {
    const index = readNotifications[type].findIndex(n => n.id === id);
    if (index !== -1) readNotifications[type].splice(index, 1);
  }
}

export const markAllNotificationsAsDeleted = (readNotifications: NotificationsSet): string[] => {
  const ids: string[] = [];
  for (const type of notificationTypes) {
    const typeIds = readNotifications[type].map(notification => notification.id);
    ids.push(...typeIds);
    readNotifications[type] = [];
  }
  return ids;
}


export const notificationBuilder = (id: string, scope: string | undefined, status: string | undefined, type: string | undefined, title: string, message: string, viewed: boolean | undefined, service: string | undefined, journeyId: string | undefined, taskId: string | undefined, businessId: string | undefined, createdAt: Date | undefined): Notification => {
  const notification: Notification = {
    id,
    scope,
    status,
    type,
    title,
    message,
    viewed,
    service,
    journeyId,
    taskId,
    businessId,
    createdAt,
    isToday: moment().utc().isSame(createdAt, 'day'),
    isYesterday: moment().utc().subtract(1, 'days').isSame(createdAt, 'day'),
    isOlder: moment().utc().subtract(2, 'days').isAfter(createdAt)
  };
  notification.timestamp = timeStamper(notification);
  // ignore all the notifications other than open banking
  return notification;
}

export const timeStamper = (notification: Notification): string => {
  const createdAtUTC: string = moment.utc(notification.createdAt).fromNow(true);
  const createdAt: string = String(notification.createdAt);
  const prefix: string = `${translate('NOTIFICATIONS.TIMESTAMP.PREFIX')}`;
  const suffix: string = `${translate('NOTIFICATIONS.TIMESTAMP.SUFFIX')}`;

  switch (getNotificationType(notification)) {
    case "today":
      return `${prefix} ${createdAtUTC} ${suffix}`;
    case "yesterday":
      return `${prefix} ${createdAtUTC} ${suffix}`;
    case "older":
      return `${formatLocaleDateTimeUTC(createdAt)}`;
    default:
      return `${createdAtUTC}`;
  }
}

export const getNotificationType = (notification: Notification) => {
  return notification.isToday ? 'today' : (notification.isYesterday ? 'yesterday' : 'older');
};

export const filterNotificationsByType = (notifications: Notification[], filter: NotificationFilter) => {
  return notifications.filter(n => filterNotification(n, filter));
};

export const filterNotification = (notification: Notification, filter: NotificationFilter): Notification | null | undefined => {
  const filterMap = {
    [NotificationFilter.TODAY]: notification.isToday,
    [NotificationFilter.YESTERDAY]: notification.isYesterday,
    [NotificationFilter.OLDER]: notification.isOlder
  };

  const servicesToCheck = [
    NotificationServices.OPEN_BANKING,
    NotificationServices.DOCUMENT_PROXY,
    NotificationServices.SCORING_PROXY,
    NotificationServices.CHECK_IBAN,
    NotificationServices.CHECK_COMPANY
  ];

  if ((servicesToCheck?.includes(<NotificationServices>notification.service)) && notification.scope === 'PERSONAL' && notification.type === 'NOTIFICATION') {
    return filterMap[filter] ? notification : null;
  }
};

export const isLastChild = (notification: Notification, Notifications: Notification[]) => {
  // set -> false if you want the orphan notification not have a border-bottom
  // to true if you want it to have a border-bottom
  if (size(Notifications) === 1) return true;
  return notification === Notifications[size(Notifications) - 1];
};

// translator utils

export const notificationTitle = (notification: Notification) => translator(notification, 'title');
export const notificationMessagePrefix = (notification: Notification) => translator(notification, 'pre-message');
export const notificationMessageSuffix = (notification: Notification) => translator(notification, 'suf-message');

export const popNotificationMessageSuffix = (notification: Notification) => translator(notification, 'pop-suf-message');

// Types pour les notifications
interface NotificationFromApi {
  service: NotificationServices;
  title: string;
  status: 'SUCCESS' | 'ERROR';
}

// Configuration des messages de traduction pour chaque service et chaque statut
const serviceConfig: Record<string, {
  success: Record<string, string>,
  error: Record<string, string>,
  titles: string[]
}> = {
  [NotificationServices.OPEN_BANKING]: {
    success: {
      title: "NOTIFICATIONS.SERVICES.OPEN_BANKING.TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.OPEN_BANKING.PREFIX_MESSAGE",
      "suf-message": "NOTIFICATIONS.SERVICES.OPEN_BANKING.SUFFIX_MESSAGE",
      "pop-suf-message": "NOTIFICATIONS.SERVICES.OPEN_BANKING.POP_SUFFIX_MESSAGE",
    },
    error: {
      title: "NOTIFICATIONS.SERVICES.OPEN_BANKING.ERROR_TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.OPEN_BANKING.ERROR_PREFIX_MESSAGE",
      "suf-message": '',
      "pop-suf-message": '',
    },
    titles: ["OpenBanking Success"]
  },
  [NotificationServices.DOCUMENT_PROXY]: {
    success: {
      title: "NOTIFICATIONS.SERVICES.LIVE_CHECK.TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.LIVE_CHECK.PREFIX_MESSAGE",
      "suf-message": "NOTIFICATIONS.SERVICES.LIVE_CHECK.SUFFIX_MESSAGE",
      "pop-suf-message": "NOTIFICATIONS.SERVICES.LIVE_CHECK.POP_SUFFIX_MESSAGE",
    },
    error: {
      title: "NOTIFICATIONS.SERVICES.LIVE_CHECK.ERROR_TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.LIVE_CHECK.ERROR_PREFIX_MESSAGE",
      "suf-message": '',
      "pop-suf-message": '',
    },
    titles: ["DocumentProxy LiveCheck"]
  },
  [NotificationServices.CHECK_COMPANY]: {
    success: {
      title: "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.PREFIX_MESSAGE",
      "suf-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.SUFFIX_MESSAGE",
      "pop-suf-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.POP_SUFFIX_MESSAGE",
    },
    error: {
      title: "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.ERROR_TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_COMPANY.ERROR_PREFIX_MESSAGE",
      "suf-message": '',
      "pop-suf-message": '',
    },
    titles: ["Check Company Batch Terminated"]
  },
  [NotificationServices.CHECK_IBAN]: {
    success: {
      title: "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.PREFIX_MESSAGE",
      "suf-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.SUFFIX_MESSAGE",
      "pop-suf-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.POP_SUFFIX_MESSAGE",
    },
    error: {
      title: "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.ERROR_TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_CHECK_IBAN.ERROR_PREFIX_MESSAGE",
      "suf-message": '',
      "pop-suf-message": '',
    },
    titles: ["Check Iban Batch Terminated"]
  },
  [NotificationServices.SCORING_PROXY]: {
    success: {
      title: "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.PREFIX_MESSAGE",
      "suf-message": "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.SUFFIX_MESSAGE",
      "pop-suf-message": "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.POP_SUFFIX_MESSAGE",
    },
    error: {
      title: "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.ERROR_TITLE",
      "pre-message": "NOTIFICATIONS.SERVICES.BATCH_SCORING_PROXY.ERROR_PREFIX_MESSAGE",
      "suf-message": '',
      "pop-suf-message": '',
    },
    titles: ["Scoring Batch Terminated"]
  }
};

// Fonction utilitaire pour récupérer la traduction
const getTranslation = (notification: any, object: string): string => {
  const config = serviceConfig[notification.service];
  const statusKey = notification.status.toLowerCase() as 'success' | 'error';

  if (config) {
    return translate(config[statusKey][object]) || '';
  }

  return '';
};

// Fonction principale
export const translator = (notification: any, object: string): string => {
  for (const [service, config] of Object.entries(serviceConfig)) {
    // Vérification du service ou du titre spécifique
    if (notification.service === service || config.titles.includes(notification.title)) {
      return getTranslation(notification, object);
    }
  }

  return '';
};