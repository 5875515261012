import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const getProfilesByCompanyManager = (profileName?: string) =>
  api.get(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles?profileName=${profileName && profileName || ''}`)
    .then(response => response.data);

export const getAllPermissionsForAProfileByCompanyManager = (profileName: string) =>
  api.get(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles/${profileName}/permissions`)
    .then(response => response.data);

export const createProfileByCompanyManager = (name?: string, description?: string, enabled?: boolean) =>
  api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles`, {
      "name": (name === '' || name?.replaceAll(' ', '') === '') ? undefined : name,
      "description": (description === '' || description?.replaceAll(' ', '') === '') ? undefined : description,
      "enabled": enabled
    })
    .then(response => response.data);

export const editProfileByCompanyManager = (oldName?: string, newName?: string, description?: string, enabled?: boolean) =>
  api.put(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles/${oldName}`, {
      "name": (newName === '' || newName?.replaceAll(' ', '') === '') ? undefined : newName,
      "description": (description === '' || description?.replaceAll(' ', '') === '') ? undefined : description,
      "enabled": enabled
    })
    .then(response => response.data);

export const editPermissionByCompanyManager = (profileName: string, body: {
  kitName: string,
  moduleName: string,
  permissionName: string
}[]) =>
  api.put(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles/${profileName}/permissions`, body)
    .then(response => response.data);

export const deletePermissionByCompanyManager = (permissionName: string) =>
  api.delete(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles/${permissionName}/permissions`)
    .then(response => response.data);

export const deleteProfileByCompanyManager = (profileName: string) =>
  api.delete(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/profiles/${profileName}`)
    .then(response => response.data);